import React, { useState } from 'react';
import Step1 from '../components/stepperAccount/Step1';
import NavBar from '../components/home/Navbar';
import FooterMinimalist from '../components/home/FooterMinimalist';
import Step2 from '../components/stepperAccount/Step2';
import Step3 from '../components/stepperAccount/Step3';
import Step4 from '../components/stepperAccount/Step4';

const RequestBankPage = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  return (
    <>
      <div className='absolute top-0'>
      <NavBar />
      </div>
        
      
      <div className='flex flex-wrap justify-center min-h-screen  w-[80%] m-[auto] mt-[100px] mb-[50px]'>
        <div className='flex justify-evenly w-full'>
          <div className={step === 1 ? 'w-[25%] h-10 p-2 bg-teal-700 text-white text-center rounded-xl' : "w-[25%] h-10 p-2 bg-white text-teal-700 text-center rounded-xl border border-teal-700"}>
            <span className="block sm:hidden">1</span>
            <span className="hidden sm:block">Formulaire</span>
          </div>
          <div className={step === 2 ? 'w-[25%] h-10 p-2 bg-teal-700 text-white text-center rounded-xl ' : "w-[25%] h-10 p-2 bg-white text-teal-700 text-center rounded-xl border border-teal-700"}> 
          <span className="block sm:hidden">2</span>
          <span className="hidden sm:block">documents</span>
          </div>
          <div className={step === 3 ? 'w-[25%] h-10 p-2 bg-teal-700 text-white text-center rounded-xl' : "w-[25%] h-10 p-2 bg-white text-teal-700 text-center rounded-xl border border-teal-700"}>
          <span className="block sm:hidden">3</span>
          <span className="hidden sm:block">récapitulatif</span>
           </div>
        </div>
        <div className="bg-white w-full h-full rounded-xl shadow-xl p-4 overflow-hidden">
          {/* h-[42rem] sm:w-[60rem] sm:h-[35rem] */}
          {/* <SideNav /> */}
          {/* <div className="flex-col">
          <div className="h-[100%] pl-[5px] overflow-y-auto">
          
          </div>
        </div> */}
          <div className='h-full overflow-y-auto'>
            {step === 1 && <Step1 setStep={setStep} formData={formData} setFormData={setFormData} />}
            {step === 2 && <Step2 setStep={setStep} formData={formData} setFormData={setFormData} actualStep={step} />}
            {step === 3 && <Step3 setStep={setStep} formData={formData} />}
            {step === 4 && <Step4 />}
          </div>

        </div>
      </div>

    
        <FooterMinimalist />
      

    </>
  );
};

export default RequestBankPage;