import React from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import { Outlet} from "react-router-dom";
import DataTableAccount from "../table/DataTableAccount";

const Layout = () => {


  const admin = JSON.parse(window?.localStorage.getItem("adminAccess"));
  console.log(admin);

  return (
    <div>
      <div className="flex">
        <SideNav />
        <div className="w-full ml-16 md:ml-56">
          <Header />
          <Outlet />
          <div className="p-5">   
              <DataTableAccount />      
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
