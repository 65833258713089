import Navigation from "../Dashboard/Navigation";
import DataTableCredit from "../table/DataTableCredit";

const LayoutManagementCreditRequests = () => {

  const admin = JSON.parse(window?.localStorage.getItem("adminAccess"));
  console.log(admin);

  return (
    <main className="w-full h-screen flex flex-row relative">
      <Navigation />
      <section className="flex flex-col p-10 ml-20 mb-20 w-full gap-5">
        <h1 className="text-4xl text-teal-800">Tableau de bord</h1>

        {/* Overview Section */}
        {/* <div className="w-full bg-white shadow-xl rounded p-5 flex flex-col justify-between">
          
          
        </div> */}
        <h2 className="text-2xl text-teal-900">Les dernières demandes de credits</h2>
        <DataTableCredit />
      </section>
    </main>
  );
};

export default LayoutManagementCreditRequests;
