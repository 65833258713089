import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from 'react-hook-form';

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { getAdminUrl } from '../../url';


const Step1 = ({ setStep, formData, setFormData, }) => {
  const navigate = useNavigate();
  const [institution,setInstitution] = useState();

  useEffect(() => {
    const fetchInstitution = async () =>{
      try {
        const response = await axios.get(getAdminUrl);
        setInstitution(response.data);
      } catch (error) {
        console.error(error)
      }
    }
    fetchInstitution();
  },[]);
  const filtredInstitution = useMemo(() => {
    let filtred = []
    filtred = institution?.filter(item => item.role === 'institution');
    return filtred;
  },[institution]);

  const { register, control, handleSubmit, setValue, watch, formState: { errors } } = useForm({
    defaultValues: formData,
  });
  const phone = watch('phone');
  const professionalStatus = watch('professional_status');
  useEffect(() => {
    if (phone) {
      setValue('phone', phone);
    }
  }, [phone, setValue]);

  const onSubmit = (data) => {
    setFormData(data);
    setStep(2);
    console.log(data);
  };
  const [company, setCompany] = useState(false);
  const handleCompany = () => {
    setCompany(!company);
  }
  return (
    <div className="p-2 h-[auto] sm:basis-[60%] w-full sm:h-[auto] sm:pr-[80px] overflow-y-auto ">
      <h1 className="mt-10 text-3xl font-[800] mb-2 text-primary-marineBlue ">
        Informations personnelles
      </h1>
      <p className="text-neutral-coolGray mb-6">
        S'il vous plaît veuillez renseigner tous les champs.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <div className="form-wrapper flex flex-col relative">
          <div className='flex justify-between'>
            <label className="text-primary-marineBlue font-[500] mb-2">
              Etablissement banquaire
            </label>
            <span
              className={`${errors.bank ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}
            >
              Ce champ est requis
            </span>
          </div>

          <select
            {...register('bank', { required: true })}
            className={`${errors.bank ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
          >
            <option value="">Sélectionner un établissement</option>
            
            {
              filtredInstitution?.map((bank,index) => (
                <option key={index} value={bank.institution_name}>{bank.institution_name}</option>
              ))
            }
          </select>

          <div className='flex justify-between'>
            <label className="text-primary-marineBlue font-[500] mb-2">
              Type de compte
            </label>
            <span
              className={`${errors.account_type ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}
            >
              Ce champ est requis
            </span>
          </div>
          <select
            {...register('account_type', { required: true })}
            className={`${errors.account_type ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
          >
            <option value="">Sélectionner un type de compte</option>
            <option value="Courant">Courant</option>
            <option value="Epargne">Epargne</option>
          </select>
          <div className="flex justify-between">
            <label className="text-primary-marineBlue font-[500] mb-2">Nom</label>
            <span className={`${errors.name ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
              Ce champ est requis
            </span>
          </div>
          <input
            {...register('name', { required: true })}
            className={`${errors.name ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
            type="text"
            placeholder="Nom"
          />

          <div className="flex justify-between">
            <label className="text-primary-marineBlue font-[500] mb-2">Prénoms</label>
            <span className={`${errors.firstname ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
              Ce champ est requis
            </span>
          </div>
          <input
            {...register('firstname', { required: true })}
            className={`${errors.firstname ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
            type="text"
            placeholder="Prénoms"
          />

          <div className="flex justify-between">
            <label className="text-primary-marineBlue font-[500] mb-2">Adresse mail</label>
            <span className={`${errors.email ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
              Ce champ est requis
            </span>
          </div>
          <input
            {...register('email', { required: true })}
            className={`${errors.email ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
            type="email"
            placeholder="ex: stephenking@lorem.com"
          />

          <div className="flex justify-between">
            <label className="text-primary-marineBlue font-[500] mb-2">Numéro de téléphone</label>
            <span className={`${errors.phone ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
              Ce champ est requis
            </span>
          </div>
          <Controller
            name="phone"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                defaultCountry="CI"
                type="tel"
                value={value}
                className="transition"
                id="phone"
                onChange={onChange}
                inputStyle={{
                  width: '100%',
                  height: '40px',
                  padding: '1.5rem 0 1.5rem 2rem'
                }}
                containerStyle={{
                  marginBottom: errors.phone ? '6px' : '0'
                }}
                inputClass={`${errors.phone ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px]`}
              />
            )}
          />


          <div className="flex justify-between">
            <label className="text-primary-marineBlue font-[500] mb-2">Date de naissance</label>
            <span className={`${errors.birth_day ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
              Ce champ est requis
            </span>
          </div>
          <input
            {...register('birth_day', { required: true })}
            className={`${errors.birth_day ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
            type="date"

          />

          <div className="flex justify-between">
            <label className="text-primary-marineBlue font-[500] mb-2">Lieu de résidence</label>
            <span className={`${errors.resid ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
              Ce champ est requis
            </span>
          </div>
          <input
            {...register('resid', { required: true })}
            className={`${errors.resid ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
            type="text"
            placeholder="ex: Abidjan, Marcory remblais"
          />

          <div className="flex justify-between">
            <label className="text-primary-marineBlue font-[500] mb-2">Profession</label>
            <span className={`${errors.job ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
              Ce champ est requis
            </span>
          </div>
          <input
            {...register('job', { required: true })}
            className={`${errors.job ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
            type="text"
            placeholder="ex: Commerçant"
          />
          <div className='flex justify-between'>
            <label className="text-primary-marineBlue font-[500] mb-2">
              Statut professionelle
            </label>
            <span
              className={`${errors.professional_status ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}
            >
              Ce champ est requis
            </span>
          </div>

          <select
            {...register('professional_status', { required: true })}
            className={`${errors.professional_status ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
          >
            <option value="">Sélectionner un statut</option>
            <option value="Employeur" >Employeur</option>
            <option value="Employé" >Employé</option>
            <option value="Indépendant">Indépendant</option>
            <option value="Commerçant">Commerçant</option>
            <option value="Etudiant">Etudiant</option>
          </select>

          {(professionalStatus === "Employé" || professionalStatus === "Employeur") ? (
            <>
              <div className="flex justify-between">
                <label className="text-primary-marineBlue font-[500] mb-2">Nom de l'entreprise</label>
                <span className={`${errors.company ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
                  Ce champ est requis
                </span>
              </div>
              <input
                {...register('company', { required: true })}
                className={`${errors.company ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
                type="text"
                placeholder="ex: Fintech Hyksos SA"
              />
            </>
          ) : (
            <></>
          )
          }

          <div className='flex justify-between'>
            <label className="text-primary-marineBlue font-[500] mb-2">
              Type de pièce d'identité
            </label>
            <span
              className={`${errors.id_card_type ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}
            >
              Ce champ est requis
            </span>
          </div>

          <select
            {...register('id_card_type', { required: true })}
            className={`${errors.id_card_type ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
          >
            <option value="">Sélectionner une pièce d'identité</option>
            <option value="Carte d'identité">Carte d'identité</option>
            <option value="Passeport">Passeport</option>
            <option value="Carte de séjour">Carte de séjour</option>
          </select>

          <div className="flex justify-between">
            <label className="text-primary-marineBlue font-[500] mb-2">Nº de la pièce d'identité</label>
            <span className={`${errors.id_card_number ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
              Ce champ est requis
            </span>
          </div>
          <input
            {...register('id_card_number', { required: true })}
            className={`${errors.id_card_number ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
            type="text"
            placeholder="ex: CI001111111"
          />
        </div>
        <div className='flex justify-end'>
          <button
            className="bg-teal-700 text-white border-0 rounded-md px-6 py-3 transition-all duration-300 hover:opacity-75"
            type="submit"
          >
            Etape suivante
          </button>
        </div>

      </form>
    </div>
  );
};

export default Step1;
