import React from "react";
import { GoBell } from "react-icons/go";
const Header = () => {
  const admin = JSON.parse(window?.localStorage.getItem("adminAccess"))
    return (
    <div className="flex justify-between items-center p-4">
      <div>
        <h1 className="text-2xl">BIENVENUE SUR LE TABLEAU DE BORD DE : {admin.institution_name} </h1>
        <p className="text-xl font-semibold"></p>
      </div>
      <div className="flex items-center space-x-5">
        
        <div className="flex items-center space-x-5">
          <button className="relative text-2xl text-gray-600 ">
            <GoBell size={28} />
            <span className="absolute top-0 right-0 -mt-1 -mr-1 flex justify-center items-center bg-[#0cb99c] text-white font-semibold text-[10px] w-4 h-4 rounded-full border-2 border-white ">
              
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
