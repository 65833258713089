import React from 'react';
import NavBar from '../components/home/Navbar';
import FooterMinimalist from '../components/home/FooterMinimalist';
import { useHistory, useNavigate } from 'react-router-dom';

const SelectRequestPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <NavBar />
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <h1 className="text-2xl font-bold mb-8">Choisissez une option</h1>
        <div className="flex  space-x-8">
          <div
            onClick={() => navigate('/nouvelle-demande/bank-request')}
            className="cursor-pointer bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 w-[100%]"
          >
            <img
              src="/images/carte-bancaire.png"
              alt="Créer un compte bancaire"
              className="w-40 h-40 object-cover mx-auto mb-4"
            />
            <h2 className="text-center text-xl font-semibold">Créer un compte bancaire</h2>
          </div>

          <div
            onClick={() => navigate('/nouvelle-demande/apply-credit')}
            className="cursor-pointer bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 w-[100%]"
          >
            <img
              src="/images/pret.png"
              alt="Demande de crédit"
              className="w-40 h-40 object-cover mx-auto mb-4"
            />
            <h2 className="text-center text-xl font-semibold">Demander un crédit</h2>
          </div>
        </div>
      </div>
      <FooterMinimalist />
    </>
  );
};

export default SelectRequestPage;
