import React from 'react';
import {
  AdjustmentsHorizontalIcon,
  ArrowTrendingUpIcon,
  BoltIcon,
  CursorArrowRaysIcon,
  PencilIcon,
  UserGroupIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import NavigationLink from "./NavigationLink";
import { Link } from 'react-router-dom';

const variants = {
  close: {
    x: -300,
    opacity: 0,
  },
  open: {
    x: 0,
    opacity: 100,
  },
};

const ProjectNavigation = ({ selectedProject, isOpen, setSelectedProject }) => {
  return (
    <motion.nav
      variants={variants}
      initial="close"
      animate="open"
      exit="close"
      transition={{
        duration: 0.25,
        ease: "easeInOut",
      }}
      className={`h-full flex flex-col gap-8 w-64 fixed bg-neutral-900 ml-0 z-[99] ${isOpen ? "left-64" : "left-20"
        } border-r border-neutral-800 p-5`}
    >
      <div className="flex flex-row w-full justify-between place-items-center">
        <h1 className="tracking-wide text-neutral-100 text-lg">
          {selectedProject}
        </h1>
        <button onClick={() => setSelectedProject(null)}>
          <XMarkIcon className="w-8 stroke-neutral-400" />
        </button>
      </div>
      {selectedProject === "Gestion des ouvertures de compte" && (
        <>
          <div className="flex flex-col gap-3">
            <Link to={("/dashboard/compte/nouvelles-demandes")}>
              <NavigationLink name="Nouvelles demandes">
                <ArrowTrendingUpIcon className="stroke-[0.75] stroke-inherit min-w-8 w-8" />
              </NavigationLink>
            </Link>
            <Link to={("/dashboard/compte/demandes-en-attente-de-rendez-vous")}>
              <NavigationLink name="Demandes en attente de rendez-vous">
                <UserGroupIcon className="stroke-[0.75] stroke-inherit min-w-8 w-8" />
              </NavigationLink>
            </Link>
            <Link to={("/dashboard/compte/demandes-en-attente-de-documents")}>
              <NavigationLink name="Demandes en attente de documents supplémentaire">
                <PencilIcon className="stroke-[0.75] stroke-inherit min-w-8 w-8" />
              </NavigationLink>
            </Link>
            <Link to={("/dashboard/compte/demandes-accepter")}>
              <NavigationLink name="Demandes accepter">
                <BoltIcon className="stroke-[0.75] stroke-inherit min-w-8 w-8" />
              </NavigationLink>
            </Link>
            <Link to={("/dashboard/compte/demandes-refuser")}>
              <NavigationLink name="Demandes réfuser">
                <CursorArrowRaysIcon className="stroke-[0.75] stroke-inherit min-w-8 w-8" />
              </NavigationLink>
            </Link>
            <Link to={("/dashboard/historiques-des-demandes")}>
              <NavigationLink name="Historique">
                <AdjustmentsHorizontalIcon className="stroke-[0.75] stroke-inherit min-w-8 w-8" />
              </NavigationLink>
            </Link>
          </div>
          <div className="flex flex-col gap-5">
            <h1 className="tracking-wide text-neutral-300">Membres de l'equipe</h1>
            <div className="flex flex-row gap-3 place-items-center">
              <UserIcon className="w-8 p-1 rounded-full stroke-2 stroke-rose-800 bg-rose-200/70" />
              <p className="tracking-wide text-neutral-400">Gestionnaire de compte</p>
            </div>
            <div className="flex flex-row gap-3 place-items-center">
              <UserIcon className="w-8 p-1 rounded-full stroke-2 stroke-emerald-800 bg-emerald-200/70" />
              <p className="tracking-wide text-neutral-400">Analyste</p>
            </div>

          </div>
        </>
      )
      }
      {selectedProject === "Gestion des demandes de crédit" && (
        <>
          <div className="flex flex-col gap-3">
            <Link to={("/dashboard/credit/nouvelles-demandes")}>
              <NavigationLink name="Nouvelles demandes">
                <ArrowTrendingUpIcon className="stroke-[0.75] stroke-inherit min-w-8 w-8" />
              </NavigationLink>
            </Link>
            <Link to={("/dashboard/credit/demandes-en-attente-de-rendez-vous")}>
              <NavigationLink name="Demandes en attente de rendez-vous">
                <UserGroupIcon className="stroke-[0.75] stroke-inherit min-w-8 w-8" />
              </NavigationLink>
            </Link>
            <Link to={("/dashboard/credit/demandes-en-attente-de-documents")}>
              <NavigationLink name="Demandes en attente de documents supplémentaire">
                <PencilIcon className="stroke-[0.75] stroke-inherit min-w-8 w-8" />
              </NavigationLink>
            </Link>
            <Link to={("/dashboard/credit/demandes-accespter")}>
              <NavigationLink name="Demandes accepter">
                <BoltIcon className="stroke-[0.75] stroke-inherit min-w-8 w-8" />
              </NavigationLink>
            </Link>
            <Link to={("/dashboard/credit/demandes-refuser")}>
              <NavigationLink name="Demandes réfuser">
                <CursorArrowRaysIcon className="stroke-[0.75] stroke-inherit min-w-8 w-8" />
              </NavigationLink>
            </Link>
            <Link to={("/dashboard/historique")}>
              <NavigationLink name="Historique">
                <AdjustmentsHorizontalIcon className="stroke-[0.75] stroke-inherit min-w-8 w-8" />
              </NavigationLink>
            </Link>
          </div>
          <div className="flex flex-col gap-5">
            <h1 className="tracking-wide text-neutral-300">Membres de l'equipe</h1>
            <div className="flex flex-row gap-3 place-items-center">
              <UserIcon className="w-8 p-1 rounded-full stroke-2 stroke-rose-800 bg-rose-200/70" />
              <p className="tracking-wide text-neutral-400">Gestionnaire de compte</p>
            </div>
            <div className="flex flex-row gap-3 place-items-center">
              <UserIcon className="w-8 p-1 rounded-full stroke-2 stroke-emerald-800 bg-emerald-200/70" />
              <p className="tracking-wide text-neutral-400">Analyste financier</p>
            </div>
            <div className="flex flex-row gap-3 place-items-center">
              <UserIcon className="w-8 p-1 rounded-full stroke-2 stroke-indigo-800 bg-indigo-200/70" />
              <p className="tracking-wide text-neutral-400">Analyste risque</p>
            </div>
          </div>
        </>
      )
      }
    </motion.nav>
  );
};

export default ProjectNavigation;
