import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { uploadUrl } from '../../url';
import ClipLoader from "react-spinners/ClipLoader";
import { FileUploader } from "react-drag-drop-files";

const Step2 = ({ setStep, formData, setFormData, actualStep }) => {
  const [documents, setDocuments] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const fileTypes = ["JPG", "JPEG", "PNG", "PDF"];

  const handleChangeFile = async (file) => {
    setLoadingUpload(true);

    const formDataToSend = new FormData();
    formDataToSend.append('file', file);

    try {
      const response = await axios.post(uploadUrl, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const uploadedFile = response.data;

      const newDocument = {
        file: uploadedFile,
        name: file.name,
        type: file.type,
        size: (file.size / 1024).toFixed(2) // Convert size to KB
      };

      setDocuments((prevDocuments) => [...prevDocuments, newDocument]);
      setLoadingUpload(false);
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier:', error);
      setLoadingUpload(false);
    }
  };

  useEffect(() => {
    if (formData.documents) {
      setDocuments(formData.documents);
    }
  }, [actualStep]);

  const handleNext = () => {
    setFormData({ ...formData, documents });
    setStep(3);
  };

  const handlePrevious = () => {
    setStep(1);
  };
  
  return (
    <div className="sm:basis-[60%] w-full h-[auto] sm:pr-[80px] overflow-y-auto ">
      <h1 className="mt-10 text-3xl font-[800] mb-2 text-primary-marineBlue">
        Téléchargement des documents
      </h1>
      <p className="text-neutral-coolGray mb-6">
        S'il vous plaît télécharger tous les documents.
      </p>
      <div className="">
        <FileUploader
          handleChange={handleChangeFile}
          name="file"
          types={fileTypes}
          hoverTitle="Veuillez sélectionner un fichier"
          classes="zone-drop col-1"
        />
      </div>

      <p className="text-danger fs-small">
        * Veuillez télécharger les documents ci-dessous :<br />
        - Recto carte d'identité <br />
        - Verso carte d'identité <br />
        - Attestation de travail <br />
        - Certificat de residence, facture CIE ou SODECI <br />
        - Photo d'identité <br />
      </p>

      <div className="pt-4 py-6">
        {documents.length > 0 && <hr />}
        {loadingUpload && (
          <div className="d-flex justify-content-center mt-2">
            <div className="d-flex justify-content-center align-items-center p-2 rounded border">
              <ClipLoader
                loading={loadingUpload}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <div className="text-custom-green">
                Veuillez patienter pour le téléchargement de votre fichier.
              </div>
            </div>
          </div>
        )}
        {documents.length > 0 &&
          documents.map((val, key) => (
            <div key={key}>
              <li
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {val.type === "application/pdf" ? (
                  <span>
                    <i
                      className="fa fa-file-pdf-o"
                      style={{ fontSize: "25px", color: "blue" }}
                    ></i>
                  </span>
                ) : (
                  <span>
                    <i
                      className="fa fa-file-image-o"
                      style={{ fontSize: "25px", color: "blue" }}
                    ></i>
                  </span>
                )}
                <span>{val.name}</span>
                <span>{val.size} Ko</span>
              </li>
              <hr />
            </div>
          ))}
      </div>
      <div className='flex justify-between'>
        <button
          className="bg-teal-700 text-white border-0 rounded-md px-6 py-3 transition-all duration-300 hover:opacity-75"
          onClick={handlePrevious}
        >
          précédent
        </button>
        <button
          className="bg-teal-700 text-white border-0 rounded-md px-6 py-3 transition-all duration-300 hover:opacity-75"
          onClick={handleNext}
        >
          suivant
        </button>
      </div>
    </div>
  );
};

export default Step2;
