import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    cssEase: "linear"
  };

  const slides = [
    {
      image: "/images/visacard.png",
      title: "Avec le service Agency banking",
      subtitle: "Ouvrez votre compte bancaire Quand & où vous voulez.",
      description: "Demandez l'ouverture de votre compte bancaire en quelques minutes, depuis n'importe où, en utilisant notre plateforme sécurisée et facile d'accès."
    },
    {
      image: "/images/queue.jpg",
      title: "Avec le service Agency banking",
      subtitle: "Faites une demande de prêt Quand & où vous voulez.",
      description: "Financer vos projets avec rapidité, fiabilité et sécurité grâce à notre application mobile."
    },
    {
      image: "/images/queue.jpg",
      title: "Avec le service Agency banking",
      subtitle: "Accédez à votre argent à tout moment.",
      description: "Gérez vos finances avec facilité et sécurité grâce à notre application mobile."
    }
    // Ajoute d'autres slides si nécessaire
  ];

  return (
    <section className="bg-white dark:bg-gray-900">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className="relative flex items-center justify-center h-screen bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${slide.image})`,
              backgroundSize: "cover",
              filter: "blur(10px)"
            }}
          >
            <div className="absolute inset-0  opacity-50"></div> {/* Overlay pour assombrir le fond */}
            <div className="relative z-10 flex flex-col items-center text-center lg:grid lg:max-w-screen-xl lg:grid-cols-12 lg:gap-8 xl:gap-0 lg:pt-28 px-4 py-8 mx-auto">
              <div className="mr-auto place-self-center lg:col-span-7">
                <h3 className="max-w-2xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-4xl xl:text-4xl text-white">
                  {slide.title}
                </h3>
                <h3 className="text-cyan-500 text-2xl mb-4">
                  {slide.subtitle}
                </h3>
                <p className="max-w-2xl mb-6 font-light text-gray-300 lg:mb-8 md:text-lg lg:text-xl">
                  {slide.description}
                </p>
                <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                  <Link
                    to={"/nouvelle-demande"}
                    className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center bg-teal-700 text-white border border-gray-200 rounded-lg sm:w-auto hover:bg-teal-400 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800 animate-pulse"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                    </svg>
                    {" "}
                    Ouvrir un compte
                  </Link>
                  <Link
                    to={"/"}
                    className="inline-flex items-center justify-center w-full px-5 py-3 mb-2 mr-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:w-auto focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    {" "}
                    En savoir plus
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default Carousel;
