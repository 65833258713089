import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { registerAgentUrl, getAgentListUrl, getAdminUrl, registerAdminUrl } from '../url';
import PhoneInput from 'react-phone-number-input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Alert,
  Snackbar,
  Box,
  CircularProgress,
  Button,
  Modal,
  Typography,
} from '@mui/material';

const ProfilePage = () => {

  const admin = JSON.parse(window?.localStorage.getItem('adminAccess' || 'userAccess'));
  // const [formData, setFormData] = useState({});
  // const [selectedRole, setSelectedRole] = useState('');
  const [agents, setAgents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [institution, setInstitution] = useState();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
  const { register, control, getValues, reset, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      agent_name: admin.agent_name,
      type_agent: admin.type_agent,
      name: admin.name,
      firstname: admin.firstname,
      email: admin.email,
      phone: admin.phone,
      address: admin.address,
      birth_day: admin.birth_day,
      institution_name: admin.institution_name,
    },
  });

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axios.get(`${getAgentListUrl}/${admin?.institution_name}`);
        setAgents(response.data);
      } catch (error) {
        console.error('There was an error fetching the List of agent!', error);
      }
    }
    fetchAgents();
  }, []);

  useEffect(() => {
    const fetchInstitution = async () => {
      try {
        const response = await axios.get(getAdminUrl);
        setInstitution(response.data);
      } catch (error) {
        console.error(error)
      }
    }
    fetchInstitution();
  }, []);

  console.log(institution)

  const onSubmit = (data) => {
    // setFormData(data);
    if (admin.role === 'institution') {
      const handleValidation = async () => {
        try {
          setLoading(true);
          await axios.post(registerAgentUrl, data);
          setLoading(false);
          setAlert({ open: true, message: 'Agent crée avec succès', severity: 'success' });
          reset();
          setOpenModal(false); // Fermer le modal
        } catch (error) {
          console.error('Erreur lors de la soumission des données:', error);
          setLoading(false);
          setAlert({ open: true, message: "Erreur lors de la création de l'agent", severity: 'error' });
        }
      };
      handleValidation();
    }
    if (admin.role === 'root') {
      const handleValidation = async () => {
        try {
          setLoading(true);
          await axios.post(registerAdminUrl, data);
          setLoading(false);
          setAlert({ open: true, message: 'Institution crée avec succès', severity: 'success' });
          reset();
          setOpenModal(false); // Fermer le modal
        } catch (error) {
          console.error('Erreur lors de la soumission des données:', error);
          setLoading(false);
          setAlert({ open: true, message: "Erreur lors de la création de l'institution", severity: 'error' });
        }
      };
      handleValidation();
    }
    if (admin.role === 'agent') {
      const handleupdateAgentInfo = async () => {

      }
      handleupdateAgentInfo();
    }
    if (admin.role === 'utilisateur') {
      const handleupdateUserInfo = async () => {

      }
      handleupdateUserInfo();
    }
  };

  const renderFormFields = () => (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col" autoComplete='off'>
      <div className="form-wrapper flex flex-col relative">
        <div className="flex justify-between">
          <label className="text-primary-marineBlue font-[500] mb-2">Type d'agent</label>
          <span className={`${errors.type_agent ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
            Ce champ est requis
          </span>
        </div>
        <select
          {...register('type_agent', { required: true })}
          className={`${errors.type_agent ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
        >
          <option disabled value="" > Veuillet sélectionner un type d'agent</option>
          <option value="Chef d'agence">Chef d'agence</option>
          <option value="Gestionnaire de compte">Gestionnaire de compte</option>
          <option value="Analyste credit">Analyste credit</option>
          <option value="Analyste risque">Analyste risque</option>
        </select>

        <div className="flex justify-between">
          <label className="text-primary-marineBlue font-[500] mb-2">Nom et prénoms</label>
          <span className={`${errors.agent_name ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
            Ce champ est requis
          </span>
        </div>
        <input
          {...register('agent_name', { required: true })}
          className={`${errors.agent_name ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
          type="text"
          placeholder="Nom et prénoms"
        />

        <div className="flex justify-between">
          <label className="text-primary-marineBlue font-[500] mb-2">E-mail</label>
          <span className={`${errors.email ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
            Ce champ est requis
          </span>
        </div>
        <input
          {...register('email', { required: true })}
          className={`${errors.email ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
          type="email"
          placeholder="E-mail "

        />

        <div className="flex justify-between">
          <label className="text-primary-marineBlue font-[500] mb-2">Numéro de téléphone</label>
          <span className={`${errors.phone ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
            Ce champ est requis
          </span>
        </div>
        <Controller
          name="phone"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              defaultCountry="CI"

              type="tel"
              value={value}
              className="transition"
              id="phone"
              onChange={onChange}
              inputStyle={{
                width: '100%',
                height: '40px',
                padding: '1.5rem 0 1.5rem 2rem'
              }}
              containerStyle={{
                marginBottom: errors.phone ? '6px' : '0'
              }}
              inputClass={`${errors.phone ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px]`}
            />
          )}
        />
      </div>
      <div className="flex justify-between">
        <label className="text-primary-marineBlue font-[500] mb-2">Nom d'utilisateur</label>
        <span className={`${errors.login ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
          Ce champ est requis
        </span>
      </div>
      <input
        {...register('login', { required: true })}
        className={`${errors.login ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
        type="text"
        placeholder="Nom d'utilisateur"
      />
      <div className="flex justify-between">
        <label className="text-primary-marineBlue font-[500] mb-2">Mot de passe</label>
        <span className={`${errors.mdp ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
          Ce champ est requis
        </span>
      </div>
      <input
        {...register('mdp', { required: true })}
        className={`${errors.mdp ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
        type="password"
        placeholder="Mot de passe"
      />

      {/* <div className='flex justify-end'>
        <button
          className="bg-teal-700 text-white border-0 rounded-md px-6 py-3 transition-all duration-300 hover:opacity-75"
          type="submit"
        >
          Etape suivante
        </button>
      </div> */}
      <Button type="submit" className="mt-6 w-full p-2 bg-teal-700 text-white rounded-md hover:bg-teal-500">
        {loading ? <CircularProgress size={24} color="inherit" /> : "Crée l'agent"}
      </Button>
    </form>
  );
  const renderFormFieldsinstitution = () => (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col" autoComplete='off'>
      <div className="form-wrapper flex flex-col relative">
        <div className="flex justify-between">
          <label className="text-primary-marineBlue font-[500] mb-2">Type d'institution</label>
          <span className={`${errors.type_institution ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
            Ce champ est requis
          </span>
        </div>
        <select
          {...register('type_institution', { required: true })}
          className={`${errors.type_institution ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
        >
          <option disabled value="" > Veuillet sélectionner un type d'agent</option>
          <option value="Bank">Banque</option>
        </select>

        <div className="flex justify-between">
          <label className="text-primary-marineBlue font-[500] mb-2">Nom de l'institution</label>
          <span className={`${errors.institution_name ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
            Ce champ est requis
          </span>
        </div>
        <input
          {...register('institution_name', { required: true })}
          className={`${errors.institution_name ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
          type="text"
          placeholder="Nom de l'institution"
        />

        <div className="flex justify-between">
          <label className="text-primary-marineBlue font-[500] mb-2">E-mail</label>
          <span className={`${errors.email ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
            Ce champ est requis
          </span>
        </div>
        <input
          {...register('email', { required: true })}
          className={`${errors.email ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
          type="email"
          placeholder="E-mail "

        />

        <div className="flex justify-between">
          <label className="text-primary-marineBlue font-[500] mb-2">Numéro de téléphone</label>
          <span className={`${errors.phone ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
            Ce champ est requis
          </span>
        </div>
        <Controller
          name="phone"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              defaultCountry="CI"

              type="tel"
              value={value}
              className="transition"
              id="phone"
              onChange={onChange}
              inputStyle={{
                width: '100%',
                height: '40px',
                padding: '1.5rem 0 1.5rem 2rem'
              }}
              containerStyle={{
                marginBottom: errors.phone ? '6px' : '0'
              }}
              inputClass={`${errors.phone ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px]`}
            />
          )}
        />
      </div>
      <div className="flex justify-between">
        <label className="text-primary-marineBlue font-[500] mb-2">Nom d'utilisateur</label>
        <span className={`${errors.login ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
          Ce champ est requis
        </span>
      </div>
      <input
        {...register('login', { required: true })}
        className={`${errors.login ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
        type="text"
        placeholder="Nom d'utilisateur"
      />
      <div className="flex justify-between">
        <label className="text-primary-marineBlue font-[500] mb-2">Mot de passe</label>
        <span className={`${errors.mdp ? "inline" : "hidden"} text-primary-strawberryRed font-[500]`}>
          Ce champ est requis
        </span>
      </div>
      <input
        {...register('mdp', { required: true })}
        className={`${errors.mdp ? "border-2 border-tomato bg-red-200  placeholder-tomato" : "focus:outline-primary-marineBlue"} mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3`}
        type="password"
        placeholder="Mot de passe"
      />

      {/* <div className='flex justify-end'>
        <button
          className="bg-teal-700 text-white border-0 rounded-md px-6 py-3 transition-all duration-300 hover:opacity-75"
          type="submit"
        >
          Etape suivante
        </button>
      </div> */}
      <Button type="submit" className="mt-6 w-full p-2 bg-teal-700 text-white rounded-md hover:bg-teal-500">
        {loading ? <CircularProgress size={24} color="inherit" /> : "Crée l'institution"}
      </Button>
    </form>
  );

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // if (loading) {
  //   return (
  //     <Box
  //       sx={{
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         height: '60vh',
  //       }}
  //     >
  //       <CircularProgress />
  //     </Box>
  //   );
  // }

  return (

    <>
      {admin.role === "agent" ? (
        <>

          <div className=' block'>
            <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">Banque</label>
            <input
              className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
              type="text"
              value={admin.institution_name}
              disabled
            />
          </div>
          <div className=' block'>
            <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">Fonction</label>
            <input
              className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
              type="text"
              value={admin.type_agent}
              disabled
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>

            <div className=' block'>
              <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">Nom et prénoms</label>
              <input
                {...register('agent_name')}
                className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
                type="text"
                value={admin.agent_name}
              />
            </div>

            <div className=' block'>
              <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">Téléphone</label>
              <input
                {...register('phone')}
                className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
                type="text"
                value={admin.phone}
                placeholder="Téléphone"

              />
            </div>

            <div className=' block'>
              <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">E-mail</label>
              <input
                {...register('email')}
                className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
                type="text"
                value={admin.email}
                placeholder="E-mail"

              />
            </div>

            <div className='block'>
              <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">Modifier le mot de passe</label>
              <input
                {...register('mdp', {
                  validate: (value) => value === '' || value?.length >= 6 || 'Le mot de passe doit comporter au moins 6 caractères',
                })}
                className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
                type="password"
                placeholder="Mot de passe"
              />
              {errors.mdp && <p className="text-red-500">{errors.mdp.message}</p>}
            </div>

            <div className='block'>
              <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">Confirmer le mot de passe</label>
              <input
                {...register('cmdp', {
                  validate: (value) => {
                    if (getValues('mdp') !== '') {
                      return value === getValues('mdp') || 'Les mots de passe ne correspondent pas';
                    }
                    return true; // Pas d'erreur si le mot de passe n'est pas renseigné
                  },
                })}
                className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
                type="password"
                placeholder="Confirmer le mot de passe"
              />
              {errors.cmdp && <p className="text-red-500">{errors.cmdp.message}</p>}
            </div>

            <Button type="submit" className="mt-6 w-full p-2 bg-teal-700 text-white rounded-md hover:bg-teal-500">
              Modifier les informations
            </Button>

          </form>


        </>
      ) : admin.role === "institution" || admin.role === "root" ? (
        <>
          <div className="container mx-auto px-4">
            <ToastContainer />
            {loading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '60vh',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {/* Informations de l'utilisateur prenant toute la ligne */}
            <div className="grid grid-cols-1 gap-4 mb-4">
              <div className="bg-white p-6 rounded-md shadow-sm w-full">
                <h2 className="text-xl font-semibold mb-4">Informations personnelles</h2>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">Nom</label>
                  <input
                    className="w-full p-3 border rounded-md"
                    type="text"
                    value={admin.agent_name || admin.institution_name}
                    disabled
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">Téléphone</label>
                  <input
                    className="w-full p-3 border rounded-md"
                    type="text"
                    value={admin.phone}
                    disabled
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">E-mail</label>
                  <input
                    className="w-full p-3 border rounded-md"
                    type="text"
                    value={admin.email}
                    disabled
                  />
                </div>
              </div>
            </div>

            {/* Bouton pour ouvrir le modal de création d'agent */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              className="mt-4 mb-6"
            >
              {admin.role === "institution" ? ("Créer un nouvel agent") : ("Créer une nouvelle institution")}
            </Button>

            {/* Modal avec Material UI */}
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box className="bg-white p-6 rounded-md w-full h-[85%] overflow-auto max-w-lg mx-auto mt-12">
                <Typography id="modal-title" variant="h6" component="h2" className="mb-4">
                  {admin.role === "institution" ? ("Créer un nouvel agent") : ("Créer une nouvelle institution")}
                </Typography>
                {admin.role === "institution" ? (renderFormFields()) : (renderFormFieldsinstitution())}
              </Box>
            </Modal>

            {/* Liste des agents créés */}
            {admin.role === "institution" && agents?.length === 0 ? (
              <div className="text-center text-gray-500 mt-12">
                Aucun agent enregistré
              </div>
            ) : admin.role === "root" && institution?.length === 0 ? (
              <div className="text-center text-gray-500 mt-12">
                Aucune institution enregistré
              </div>
            ) : (
              <div className="mt-8">
                <h2 className="text-lg font-semibold mb-4">{admin.role === "institution" ? ("Liste des agents enregistrés") : ("Liste des institutions enregistrés")}</h2>
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white shadow-md rounded-lg">
                    <thead>
                      <tr>
                        <th className="py-2 px-4 bg-gray-200 font-semibold text-left">Nom</th>
                        <th className="py-2 px-4 bg-gray-200 font-semibold text-left">E-mail</th>
                        <th className="py-2 px-4 bg-gray-200 font-semibold text-left">Téléphone</th>
                        <th className="py-2 px-4 bg-gray-200 font-semibold text-left">{admin.role === "institution" ? ("type d'agent") : ("type d'institution")}</th>
                        <th className="py-2 px-4 bg-gray-200 font-semibold text-left">Nom d'utilisateur</th>
                      </tr>
                    </thead>
                    <tbody>
                      {admin.role === "institution" ? (
                        agents.map((agent, index) => (
                          <tr key={index} className="border-b">
                            <td className="py-2 px-4">{agent.agent_name}</td>
                            <td className="py-2 px-4">{agent.email}</td>
                            <td className="py-2 px-4">{agent.phone}</td>
                            <td className="py-2 px-4">{agent.type_agent}</td>
                            <td className="py-2 px-4">{agent.login}</td>
                          </tr>
                        ))
                      ) : (
                        institution?.map((item, index) => (
                          <tr key={index} className="border-b">
                            <td className="py-2 px-4">{item.institution_name}</td>
                            <td className="py-2 px-4">{item.email}</td>
                            <td className="py-2 px-4">{item.phone}</td>
                            <td className="py-2 px-4">{item.type_institution}</td>
                            <td className="py-2 px-4">{item.login}</td>
                          </tr>
                        ))
                      )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            )
            }
          </div>
        </>
      ) : admin.role === "utilisateur" ? (
        <>
          <div className=' block'>
            <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">Nom</label>
            <input
              className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
              type="text"
              value={admin.name}
              disabled
            />
          </div>
          <div className=' block'>
            <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">Prénom</label>
            <input
              className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
              type="text"
              value={admin.firstname}
              disabled
            />
          </div>
          <div className=' block'>
            <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">Adresse</label>
            <input
              className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
              type="text"
              value={admin.address}
              disabled
            />
          </div>
          <div className=' block'>
            <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">Date de naissance</label>
            <input
              className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
              type="text"
              value={admin.birth_day}
              disabled
            />
          </div>
          <div className=' block'>
            <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">Téléphone</label>
            <input
              className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
              type="text"
              value={admin.phone}
              placeholder="Téléphone"
              disabled
            />
          </div>
          <div className=' block'>
            <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">E-mail</label>
            <input
              className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
              type="text"
              value={admin.email}
              placeholder="E-mail"
              disabled
            />
          </div>
        </>
      ) : ("")
      }
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={() => setAlert({ ...alert, open: false })}>
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProfilePage;
