{/*localhost url*/}

// const baseUrl = "http://localhost:8000";

{/*deploy url*/}

const baseUrl = "https://backend-agency-banking-d08eb24f9a05.herokuapp.com";

const loginUserUrl = `${baseUrl}/api/users/login`;
const loginAdminUrl = `${baseUrl}/api/institutions/loginAdmin`;
const registerAdminUrl = `${baseUrl}/api/institutions/registerAdmin`;
const getAdminUrl = `${baseUrl}/api/institutions/getAdmin`;
const loginAgentUrl = `${baseUrl}/api/agents/loginAgent`;
const registerUrl = `${baseUrl}/api/users/register`;
const registerAgentUrl = `${baseUrl}/api/agents/registerAgent`;
const getAgentListUrl = `${baseUrl}/api/agents/getAgentList`;
const uploadUrl = `${baseUrl}/api/uploads-files`;
const downloadDocUrl = `${baseUrl}/api/documents`;
const createBankRequestUrl = `${baseUrl}/api/bank-requests`;
const updateStatusBankRequestUrl = `${baseUrl}/api/bank-requests/update-status`;
const updateAppointmentBankRequestUrl = `${baseUrl}/api/bank-requests/update-appointment-date`;
const updateAdditionalInfoBankRequestUrl = `${baseUrl}/api/bank-requests/update-additional-info`;
const updateReasonForRefusalBankRequestUrl = `${baseUrl}/api/bank-requests/update-reason-for-refusal`;
const BankRequestByEmailUrl = `${baseUrl}/api/bank-requests/detail`;
const bankStatisticsUrl = `${baseUrl}/api/bank-requests/statistics`;
const listBankParNameUrl = `${baseUrl}/api/bank-requests/listBankRequest`;
const createCreditRequestUrl = `${baseUrl}/api/credit-requests`;
const updateCreditStatusRequestUrl = `${baseUrl}/api/credit-requests/update-credit-status`;
const updateCreditAppointmentRequestUrl = `${baseUrl}/api/credit-requests/update-credit-appointment-date`;
const updateCreditAdditionalInfoRequestUrl = `${baseUrl}/api/credit-requests/update-credit-additional-info`;
const updateCreditReasonForRefusalRequestUrl = `${baseUrl}/api/credit-requests/update-credit-reason-for-refusal`;
const CreditRequestByEmailUrl = `${baseUrl}/api/credit-requests/detail`;
const CreditStatisticsUrl = `${baseUrl}/api/credit-requests/statistics`;
const listCreditParNameUrl = `${baseUrl}/api/credit-requests/listCreditRequest`;
const sendSmsUrl = `${baseUrl}/api/send-sms`;


export{
  baseUrl,
  loginUserUrl,
  registerAdminUrl,
  loginAdminUrl,
  getAdminUrl,
  loginAgentUrl,
  registerUrl,
  registerAgentUrl,
  getAgentListUrl,
  uploadUrl,
  downloadDocUrl,
  createBankRequestUrl,
  updateStatusBankRequestUrl,
  updateAppointmentBankRequestUrl,
  updateAdditionalInfoBankRequestUrl,
  updateReasonForRefusalBankRequestUrl,
  BankRequestByEmailUrl,
  listBankParNameUrl,
  createCreditRequestUrl,
  updateCreditStatusRequestUrl,
  updateCreditAppointmentRequestUrl,
  updateCreditAdditionalInfoRequestUrl,
  updateCreditReasonForRefusalRequestUrl,
  CreditRequestByEmailUrl,
  listCreditParNameUrl,
  CreditStatisticsUrl,
  bankStatisticsUrl,
  sendSmsUrl
}

