import ProfilePage from "../../pages/ProfilePage";
import Navigation from "../Dashboard/Navigation";

const LayoutProfile = () => {

  const admin = JSON.parse(window?.localStorage.getItem("adminAccess"));
  console.log(admin);

  return (
    <main className="w-full h-screen flex flex-row relative">
      <Navigation />
      <section className="flex flex-col p-10 ml-20 mb-20 w-full gap-5">
        <h1 className="text-4xl text-teal-800">Mon profile</h1>

        {/* Overview Section */}
        <ProfilePage />
        
      </section>
    </main>
  );
};

export default LayoutProfile;
