import React from 'react';
import Main from '../components/home/Main';
import NavBar from '../components/home/Navbar';
import Footer from '../components/home/Footer';

const HomePage = () => {
  return (
    <>
    <NavBar/>
      <Main />
    <Footer/>
    </>
  );
};

export default HomePage;