import React, { useState } from "react";

// ICONS //
import { HiCheckCircle } from "react-icons/hi";
import { HiClipboardList } from "react-icons/hi";
import { HiOutlineArrowCircleLeft } from "react-icons/hi";
import { HiPause } from "react-icons/hi";
import { HiUserCircle } from "react-icons/hi";
import { HiXCircle } from "react-icons/hi";
import {HiCalendar} from "react-icons/hi";
import { HiClock } from "react-icons/hi";


import { LuBox} from "react-icons/lu";


import { Link, useNavigate, useNavigation } from "react-router-dom";

const SideNav = () => {
  const navigate = useNavigate()
  const [activeLink, setActiveLink] = useState(0);
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  const SIDEBAR_LINKS = [
    
    { id: 1, path: '/dashboard/nouvelles-demandes', name: "Nouvelles demandes", icon: HiClipboardList },
    { id: 2, path: "/dashboard/demandes-en-rendez-vous", name: "Demandes en attente de rendez-vous", icon: HiCalendar },
    { id: 3, path: "/dashboard/demandes-en-attente", name: "Demandes en attente de complements de documents", icon: HiPause },
    { id: 4, path: "/dashboard/demandes-accepter", name: "demandes accepter", icon: HiCheckCircle },
    { id: 5, path: "/dashboard/demandes-rejetter", name: "Demandes rejetter", icon: HiXCircle},
    { id: 6, path: "/dashboard/historique-demandes", name: "Historique", icon: HiClock },
    { id: 7, path: "/dashboard/profile", name: "Mon compte", icon: HiUserCircle },
  ];
  return (
    <div className="w-16 md:w-56 fixed left-0 top-0 z-10 h-screen boder-r pt-8 px-4 bg-teal-500">
      {/* logo */}
      <div className="mb-8">
        <img src="/images/Abanking.png" alt="logo" className="w-28 hidden md:flex" />
        <img src="/logo_mini.svg" alt="logo" className="w-8 flex md:hidden" />
      </div>
      {/* logo */}

      {/* Navigation Links */}
      <ul className="mt-6 space-y-6">
        {SIDEBAR_LINKS.map((link, index) => (
          <li
            key={index}
            className={`font-medium rounded-md py-2 px-5 hover:bg-teal-400 hover:text-slate-300 ${activeLink === index ? "bg-teal-400 text-white" : ""
              }`}
          >
            <Link
              to={link.path}
              className="flex justify-center md:justify-start items-center md:space-x-5 hover:text-white"
              onClick={() => handleLinkClick(index)}
            >
              <span className="text-2xl text-white">{link.icon()}</span>
              <span className="text-sm text-white  hidden md:flex ">
                {link.name}
              </span>
            </Link>
          </li>
        ))}
      </ul>
      {/* Navigation Links */}

      <div className="w-full absolute bottom-5 left-0 px-4 py-2 cursor-pointer text-center" onClick={() => {
            window.localStorage.clear();
            navigate("/login")
          }}>
        <p className="flex items-center space-x-2 text-xs text-white p-2 bg-gradient-to-r from-teal-400 to-teal-500 rounded-full">
           <span className="text-2xl"><HiOutlineArrowCircleLeft/></span>
          <span 
            className="hidden md:flex">
            Se déconnecter
          </span>
        </p>
      </div>
    </div>
  );
};

export default SideNav;