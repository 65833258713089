import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import SideNav from '../components/Dashboard/SideNav';
import Header from '../components/Dashboard/Header';
import { BankRequestByEmailUrl, updateStatusBankRequestUrl, updateAdditionalInfoBankRequestUrl, updateAppointmentBankRequestUrl, updateReasonForRefusalBankRequestUrl, downloadDocUrl, sendSmsUrl } from '../url';
import { FaCheckCircle, FaCalendarAlt, FaPlusCircle, FaTimesCircle } from "react-icons/fa";
import { IoIosCloudDownload } from "react-icons/io";
import toast from 'react-hot-toast';
import Navigation from '../components/Dashboard/Navigation';
const DetailsUsersPage = () => {
  const { email } = useParams();
  const [user, setUser] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [isMissingInfoDialogOpen, setMissingInfoDialogOpen] = useState(false);
  const [isAppointmentDialogOpen, setAppointmentDialogOpen] = useState(false);
  const [isValidationDialogOpen, setValidationDialogOpen] = useState(false);
  const [isRejectionDialogOpen, setRejectionDialogOpen] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [missingInfo, setMissingInfo] = useState({});
  const [reasonForRefusal, setReasonForRefusal] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const response = await axios.get(`${BankRequestByEmailUrl}/${email}`);
        setUser(response.data);
        setRequestId(response.data.bank_request_id);
      } catch (error) {
        console.error('Error fetching request details:', error);
      }
    };
    fetchRequest();
  }, [email]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(`${downloadDocUrl}/${requestId}`);
        setDocuments(response.data);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, [requestId]);

  // const sendSMS = async (phoneNumber, message) => {
  //   try {
  //     const response = await axios.post(sendSmsUrl, { phoneNumber, message });
  //     if (response.data.success) {
  //       console.log('SMS envoyé avec succès');
  //     } else {
  //       console.error('Erreur lors de l\'envoi du SMS');
  //     }
  //   } catch (error) {
  //     console.error('Erreur:', error.message);
  //   }
  // };

  console.log("doc", documents);

  const sendSMS = async (phoneNumber, message) => {

    const username = "fintechhyksos";
    const password = "GIUdoZ6dJHpkzNqHZbbsSpIwOJHHHQaK";
    const sender = "MT4africa";
    const recipient = phoneNumber;
    const msg = message;
    try {

      const data = new URLSearchParams();
      data.append('username', username);
      data.append('password', password);
      data.append('msisdn', recipient);
      data.append('msg', msg);
      data.append('sender', sender);
      data.append('allowunicode', 'true');

      setLoading(true);

      await axios.post(
        "https://api-public-2.mtarget.fr/messages", data,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );
      alert("SMS envoyé avec succès");
    } catch (error) {
      console.error("Erreur lors de l'envoi du SMS :", error);
      alert("Erreur lors de l'envoi du SMS");
    } finally {
      setLoading(false);
    }
  };

  // const formattedbirthDay = new Intl.DateTimeFormat('fr-FR', {
  //   day: '2-digit',
  //   month: '2-digit',
  //   year: 'numeric',
  // }).format(new Date(user?.birth_day));

  const handleStatusUpdate = async (newStatus) => {
    try {
      let message = "";
      if (newStatus === 'refused') {
        openRejectionDialog();
        return;
      }
      if (newStatus === 'validated') {
        openValidationDialog();
        return;
      }
      if (newStatus === 'pending') {
        openMissingInfoDialog();
        return;
      }
      if (newStatus === 'appoinment') {
        openAppointmentDialog();
        return;
      }

      await axios.post(`${updateStatusBankRequestUrl}/${email}`, {
        request_id: requestId,
        status: newStatus,
      });

      const formattedDate = new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(new Date(appointmentDate));

      switch (newStatus) {
        case 'validated':
          message = "Votre demande a été validée.";
          break;
        case 'pending':
          message = "Nous avons besoin d'informations supplémentaires pour votre demande.";
          break;
        case 'appoinment':
          message = `Bonjour ${user.name + " " + user.firstname}\n La banque ${user.bank_name} Votre rendez-vous est fixé au ${formattedDate}.`;
          break;
        case 'refused':
          message = "Votre demande a été refusée.";
          break;
        default:
          message = "";
      }

      if (message) {
        const smsResult = await sendSMS(user.phone, message);
        if (!smsResult.success) {
          toast.error(smsResult.message);
        }
      }
      toast.success(`Statut de la demande mis à jour`);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut ou de l'envoi de l'email :", error);
    }
  };

  console.log(user);

  const openMissingInfoDialog = () => setMissingInfoDialogOpen(true);
  const closeMissingInfoDialog = () => setMissingInfoDialogOpen(false);

  const openAppointmentDialog = () => setAppointmentDialogOpen(true);
  const closeAppointmentDialog = () => setAppointmentDialogOpen(false);

  const openValidationDialog = () => setValidationDialogOpen(true);
  const closeValidationDialog = () => setValidationDialogOpen(false);

  const openRejectionDialog = () => setRejectionDialogOpen(true);
  const closeRejectionDialog = () => setRejectionDialogOpen(false);

  const handleAppointmentDateChange = (date) => setAppointmentDate(date);

  const submitMissingInfo = async () => {
    try {
      await axios.post(`${updateAdditionalInfoBankRequestUrl}/${email}`, {
        request_id: requestId,
        status: 'missing_info',
        additional_info: missingInfo.additionalInfo,
      });
      const message = `
                      \nBonjour ${user.name}

                      \nL'etablissement bancaire : ${user.bank_name} dans laquelle vous avez soumis la demande d'ouverture de compte bancaire semble être incomplete après vérification.

                      \nRaison(s) :
                      \n${missingInfo.additionalInfo}

                      \nSur la base des raisons précisées ci-dessus, veuillez associer les éléments requis via ce lien :

                      \nhttps://agencybanking.mobiletransaction4africa.tech/login/
                      \nCordialement,
                      \nAGENCY BANKING
                      `
      const smsResult = await sendSMS(user.phone, message);
      if (!smsResult.success) {
        toast.error(smsResult.message);
      }
      closeMissingInfoDialog();
      toast.success('Informations manquantes mises à jour.');
    } catch (error) {
      console.error('Error updating request:', error);
    }
  };

  const submitAppointmentDate = async () => {
    try {
      await axios.post(`${updateAppointmentBankRequestUrl}/${email}`, {
        request_id: requestId,
        status: 'appointment',
        appointment_date: appointmentDate,
      });
      const message = `
                      \nBonjour ${user.name}

                      \nL'etablissement bancaire : ${user.bank_name} voudrais etablir un rendez-vous pour plus de précisions sur votre demande d'ouverture de compte bancaire.

                      \nDate du rendez-vous :
                      \n${appointmentDate}

                      \nVeuillez nous confirmer votre disponibilité pour le rendez-vous via ce lien :

                      \nhttps://agencybanking.mobiletransaction4africa.tech/login/
                      \nCordialement,
                      \nAGENCY BANKING
                      `
      const smsResult = await sendSMS(user.phone, message);
      if (!smsResult.success) {
        toast.error(smsResult.message);
      }
      closeAppointmentDialog();
      toast.success('Rendez-vous mis à jour.');
    } catch (error) {
      console.error('Error updating request:', error);
    }
  };

  const submitValidation = async () => {
    try {
      await axios.post(`${updateStatusBankRequestUrl}/${email}`, {
        request_id: requestId,
        status: 'validated',

      });
      const message = `
                      \nBonjour ${user.name}

                      \nL'etablissement bancaire : ${user.bank_name} est heureux de vous annoncer que votre demande de création de compte bancaire a été valider avec succès.

                      \nVeuillez vous connecter sur votre plateforme pour prendre connaissance de la suite de la procédure via ce lien :

                      \nhttps://agencybanking.mobiletransaction4africa.tech/login/
                      \nCordialement,
                      \nAGENCY BANKING
                      `
      const smsResult = await sendSMS(user.phone, message);
      if (!smsResult.success) {
        toast.error(smsResult.message);
      }
      closeValidationDialog();
      toast.success('Demande validée.');
    } catch (error) {
      console.error('Erreur lors de la validation de la demande :', error);
    }
  };

  const submitRejection = async () => {
    try {
      await axios.post(`${updateStatusBankRequestUrl}/${email}`, {
        request_id: requestId,
        status: 'refused',
        reason_for_refusal: reasonForRefusal.refusalInfo,
      });
      const message = `
                      \nBonjour ${user.name}

                      \nL'etablissement bancaire : ${user.bank_name} est navré de vous informer que votre demande d'ouverture de compte bancaire a été refusé.

                      \nRaison(s) :
                      \n${reasonForRefusal.refusalInfo}

                      \nRéessayez votre procédure de demande via le lien :

                      \nhttps://agencybanking.mobiletransaction4africa.tech/nouvelle-demande/
                      \nCordialement,
                      \nAGENCY BANKING
                      `
      const smsResult = await sendSMS(user.phone, message);
      if (!smsResult.success) {
        toast.error(smsResult.message);
      }
      closeRejectionDialog();
      toast.success(`Demande rejetée`);
    } catch (error) {
      console.error('Erreur lors du rejet de la demande :', error);
    }
  };

  if (!user) {
    return <div>Chargement...</div>;
  }
  const birth_day = new Intl.DateTimeFormat('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(user?.birth_day));

  console.log("missinginfo", missingInfo)
  return (
    <>

      <main className="w-full h-screen flex flex-row relative">
        <Navigation />
        <section className="flex flex-col p-10 ml-20 mb-20 w-full gap-5">
          <h1 className="text-4xl text-teal-800">Tableau de bord</h1>

          {/* Overview Section */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6 p-5">
            <div className="bg-white border border-gray-100 shadow-md shadow-black/5 p-6 rounded-md lg:col-span-2">
              <div className="flex justify-between mb-4 items-start">
                <div className="font-medium">Informations de la demande</div>

              </div>
              <div className="p-3 flex justify-between flex-wrap">
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden ">

                  <span className="text-gray-400 text-sm">Nom</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.name}</div>
                    {/* <span className="p-1 rounded text-[12px] font-semibold bg-blue-500/10 text-blue-500 leading-none ml-1">
                        $80
                      </span> */}
                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Prénom</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.firstname}</div>

                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Date de naissance</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">
                      {birth_day}
                    </div>

                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Téléphone</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.phone}</div>
                    {/* <span className="p-1 rounded text-[12px] font-semibold bg-blue-500/10 text-blue-500 leading-none ml-1">
                        $80
                      </span> */}
                  </div>
                </div>
              </div>
              <div className="p-3 flex justify-between flex-wrap">

                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">E-mail</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.email}</div>

                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Adresse</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.address}</div>

                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Type de pièce d'identité</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.id_card_type}</div>
                    {/* <span className="p-1 rounded text-[12px] font-semibold bg-blue-500/10 text-blue-500 leading-none ml-1">
                        $80
                      </span> */}
                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Numéro de la pièce d'identité</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.id_card_number}</div>

                  </div>
                </div>
              </div>
              <div className="p-3 flex justify-between flex-wrap">

                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Statut professionelle</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.professional_status}</div>

                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Métier</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.job}</div>
                    {/* <span className="p-1 rounded text-[12px] font-semibold bg-blue-500/10 text-blue-500 leading-none ml-1">
                        $80
                      </span> */}
                  </div>
                </div>
                {(user.professional_status === "Employé" || user.professional_status === "Employeur") && (
                  <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                    <span className="text-gray-400 text-sm">Entreprise</span>
                    <div className="flex items-center mb-0.5">
                      <div className="text-xl font-semibold">{user.company}</div>

                    </div>
                  </div>
                )
                }

                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Type de compte</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.account_type}</div>

                  </div>
                </div>
              </div>
              <div className="p-3 flex justify-between flex-wrap">

              </div>

            </div>
            <div className="bg-white border border-gray-100 shadow-md shadow-black/5 p-6 rounded-md">
              <div className="flex justify-between mb-4 items-start">
                <div className="font-medium">Documents</div>
              </div>
              <div className="overflow-x-auto">
                <div className=" gap-4">
                  <div className="py-3 bg-white rounded-xl space-y-4">
                    <div className="p-5 rounded-md border border-dashed border-gray-200">

                      <span className="text-gray-400 text-sm">Pièces jointes</span>
                      <div className="flex items-center mb-0.5">
                        <div className="text-xl font-semibold">
                          {documents.map(doc => (
                            <div key={doc.id}>
                              <a href={doc.file_url} className='flex' target="_blank" rel="noopener noreferrer">
                                <span><IoIosCloudDownload /></span><span>document</span>
                              </a>
                            </div>
                          ))}
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

                <div className="flex justify-between mb-4 items-start">
                  <div className="font-medium">Décisions</div>
                </div>
                {(user.status !== "validated" || user.status !== "refused") && (
                  <>
                    <div className="gap-4">
                      <div onClick={() => handleStatusUpdate('validated')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-emerald-300 bg-teal-500">
                        Validé la demande
                        <button className=' text-2xl z-100 text-emerald-500  rounded-full p-1'><FaCheckCircle /></button>
                      </div>
                      <div onClick={() => handleStatusUpdate('pending')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-gray-300 bg-teal-500">
                        Informations manquantes
                        <span className=' text-2xl z-100 text-white p-1'><FaPlusCircle /></span>
                      </div>
                      <div onClick={() => handleStatusUpdate('appoinment')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-orange-300 bg-teal-500">
                        Prise de rendez-vous
                        <span className=' text-2xl z-100 text-orange-500 p-1'><FaCalendarAlt /></span>
                      </div>
                      <div onClick={() => handleStatusUpdate('refused')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-red-300 bg-teal-500">
                        Refusé la demande
                        <span className=' text-2xl z-100 text-red-500 p-1'><FaTimesCircle /></span>
                      </div>
                    </div>
                  </>
                )

                }

              </div>
            </div>
          </div>
        </section>
      </main>


      {isMissingInfoDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Ajouter des informations manquantes</h2>
            <div>
              <label className="block mb-2">Informations:</label>
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md"
                rows="4"
                value={missingInfo.additionalInfo || ''}
                onChange={(e) => setMissingInfo({ additionalInfo: e.target.value })}
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
                onClick={closeMissingInfoDialog}
              >
                Annuler
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
                onClick={submitMissingInfo}
              >
                Soumettre
              </button>
            </div>
          </div>
        </div>
      )}

      {isAppointmentDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Prendre rendez-vous</h2>
            <div>
              <label className="block mb-2">Date du rendez-vous:</label>
              <input
                type="date"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={appointmentDate || ''}
                onChange={(e) => handleAppointmentDateChange(e.target.value)}
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
                onClick={closeAppointmentDialog}
              >
                Annuler
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
                onClick={submitAppointmentDate}
              >
                Soumettre
              </button>
            </div>
          </div>
        </div>
      )}

      {isValidationDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Valider la demande</h2>
            <p>Êtes-vous sûr de vouloir valider cette demande ?</p>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
                onClick={closeValidationDialog}
              >
                Annuler
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded-md"
                onClick={submitValidation}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      )}

      {isRejectionDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Refuser la demande</h2>
            <p>Êtes-vous sûr de vouloir refuser cette demande ?</p>
            <div>
              <label className="block mb-2">Raisons de refus:</label>
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md"
                rows="4"
                value={reasonForRefusal.refusalInfo || ''}
                onChange={(e) => setReasonForRefusal({ refusalInfo: e.target.value })}
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
                onClick={closeRejectionDialog}
              >
                Annuler
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md"
                onClick={submitRejection}
              >
                Refuser
              </button>
            </div>
          </div>
        </div>
      )}
      {loading && <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">Chargement...</div>}
    </>
  );
};

export default DetailsUsersPage;