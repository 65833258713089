import React, { useState, useEffect } from 'react';
import { listBankParNameUrl } from '../../url';
import { Box, Avatar, Button, Card, CardHeader } from '@mui/material';
import { HiOutlinePencilAlt, HiOutlineDownload } from "react-icons/hi";
import { NavLink, useLocation } from 'react-router-dom';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { MaterialReactTable, useMaterialReactTable, createMRTColumnHelper } from 'material-react-table';

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor('user_id', {
    header: 'ID',
    size: 20,
  }),
  columnHelper.accessor('name', {
    header: 'Nom',
    size: 120,
  }),
  // columnHelper.accessor('firstname', {
  //   header: 'Prénom',
  //   size: 120,
  // }),
  columnHelper.accessor('phone', {
    header: 'Téléphone',
    size: 120,
  }),
  columnHelper.accessor('email', {
    header: 'E-mail',
    size: 220,
  }),
  columnHelper.accessor('account_type', {
    header: 'Compte',
    size: 100,
  }),
  columnHelper.accessor('request_date', {
    header: 'Date et Heure',
    size: 180,
    Cell: ({ cell }) => {
      const formattedDate = new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(new Date(cell.getValue()));
      return formattedDate;
    },
  }),
  columnHelper.accessor('Actions', {
    header: 'Actions',
    size: 100,
    Cell: ({ row }) => (
      <NavLink to={`/dashboard/compte/detail-demande/${row.original.email}`}>
        <Avatar sx={{ width: '30px', height: '30px' }}>
          <HiOutlinePencilAlt />
        </Avatar>
      </NavLink>
    ),
  }),
];

const DataTableAccount = () => {
  const location = useLocation();
  const admin = JSON.parse(window?.localStorage.getItem("adminAccess"));
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${listBankParNameUrl}/${admin.institution_name}`);
        setData(response.data);
      } catch (error) {
        console.error('There was an error fetching the bank requests!', error);
      }
    };
    fetchData();
  }, [admin.institution_name]);

  useEffect(() => {
    if (location.pathname === "/dashboard/compte/nouvelles-demandes" || location.pathname === "/dashboard/accueil") {
      setUsers(data.filter(element => element.status === "new"));
    } else if (location.pathname === "/dashboard/compte/historique-demandes") {
      setUsers(data.filter(element => element.status !== "new"));
    } else if (location.pathname === "/dashboard/compte/demandes-en-attente-de-documents") {
      setUsers(data.filter(element => element.status === "missing_info"));
    } else if (location.pathname === "/dashboard/compte/demandes-en-attente-de-rendez-vous") {
      setUsers(data.filter(element => element.status === "appointment"));
    } else if (location.pathname === "/dashboard/compte/demandes-refuser") {
      setUsers(data.filter(element => element.status === "refused"));
    } else if (location.pathname === "/dashboard/compte/demandes-accepter") {
      setUsers(data.filter(element => element.status === "validated"));
    }
  }, [data, location.pathname]);
  // const [statusUser,setStatusUser] = useState(null);
  // useEffect(() => {
  //   setStatusUser(data.status);
  // },[data]);

  // console.log("statusUser :", statusUser);
  const handleExportRows = (rows) => {
    const doc = new jsPDF();

    // Exclude the last column ('Actions') from headers and rows
    const tableHeaders = columns.slice(0, -1).map((c) => c.header);
    const tableData = rows.map((row) => {
      const formattedDate = new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(new Date(row.original.request_date));
      return [
        row.original.user_id,
        row.original.name,
        row.original.firstname,
        row.original.phone,
        row.original.email,
        row.original.account_type,
        formattedDate,
      ];
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save('bank-requests.pdf');
  };

  const table = useMaterialReactTable({
    columns,
    data: users,  // Ensure this is linked to the `users` state
    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap' }}>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
          startIcon={<HiOutlineDownload />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<HiOutlineDownload />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<HiOutlineDownload />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return (
    <>
    {users.length > 0 ?(
      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        <MaterialReactTable table={table} />
      </Box>
    ):(
      <div>Aucune procédure enregistrée ici pour l'instant</div>
    )
    }
      
      {/* {users.length > 0 ? (
        <div className=' overflow-x-auto flex justify-center'>
          <Card className=' border-xl border-teal-300 shadow-2xl'>
            <CardHeader className='bg-teal-300 ' title="Les dernières demandes" />
            <MaterialReactTable table={table} />
          </Card>
        </div>


      ) : (
        <div>Aucune procédure enregistrée ici pour l'instant</div>
      )} */}
    </>
  );
};

export default DataTableAccount;
