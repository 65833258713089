import React, { useState } from 'react';
import axios from 'axios'
import { createBankRequestUrl } from '../../url';
import { registerUrl } from '../../url';
import ClipLoader from 'react-spinners/ClipLoader';
const Step3 = ({ setStep, formData }) => {
  const [loading, setLoading] = useState(false);

  const handleValidation = async () => {


    // // console.log("register formData", {
    // //   name: formData.name,
    // //   firstname: formData.firstname,
    // //   email: formData.email,
    // //   birth_day: formData.birth_day,
    // //   phone: formData.phone,
    // //   address: formData.resid,
    // // })

    // // console.log("bank request", {
    // //   formData_id: 2,
    // //   bank_name: formData.bank,
    // //   account_type: formData.account_type,
    // //   professional_status: formData.professional_status,
    // //   job: formData.job,
    // //   company: formData.company,
    // //   id_card_type: formData.id_card_type,
    // //   id_card_number: formData.id_card_number,
    // //   documents: formData.documents
    // // })


    const sendSMS = async (phoneNumber, message) => {

      const username = "fintechhyksos";
      const password = "GIUdoZ6dJHpkzNqHZbbsSpIwOJHHHQaK";
      const sender = "MT4africa";
      const recipient = phoneNumber;
      const msg = message;
      try {
  
        const data = new URLSearchParams();
        data.append('username', username);
        data.append('password', password);
        data.append('msisdn', recipient);
        data.append('msg', msg);
        data.append('sender', sender);
        data.append('allowunicode', 'true');
  
        setLoading(true);
  
        await axios.post(
          "https://api-public-2.mtarget.fr/messages", data,
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        );
        alert("SMS envoyé avec succès");
      } catch (error) {
        console.error("Erreur lors de l'envoi du SMS :", error);
        alert("Erreur lors de l'envoi du SMS");
      } finally {
        setLoading(false);
      }
    };

    try {
      setLoading(true);
      // Envoi des données personnelles à /api/formDatas
      const userResponse = await axios.post(registerUrl, {
        name: formData.name,
        firstname: formData.firstname,
        email: formData.email,
        birth_day:formData.birth_day,
        phone:formData.phone,
        address: formData.resid,
      });

      // Récupération de l'ID de l'utilisateur créé
      const userId = userResponse.data.dataUser.id;

      // Envoi des données bancaires à /api/bank-requests avec l'ID de l'utilisateur
      await axios.post(createBankRequestUrl, {
        user_id: userId,
        bank_name: formData.bank,
        account_type:formData.account_type,
        professional_status:formData.professional_status,
        job:formData.job,
        email:formData.email,
        company:formData.company,
        id_card_type:formData.id_card_type,
        id_card_number:formData.id_card_number,	
        documents:formData.documents
      });
      setStep(4);
      const message = `
                      \nBonjour ${formData.name}

                      \nL'etablissement bancaire : ${formData.bank_name} dans laquelle vous avez soumis la demande d'ouverture de compte bancaire a bien reçu votre demande.

                      \nNous vous tiendrons informer de l'avancement du traitement de votre requette , penant ce temps veuillez vous connecter sur votre plateforme client grace aux identifiants qui vous ont été communiqués pour mieux suivre votre dossier :

                      \nhttps://agencybanking.mobiletransaction4africa.tech/login/
                      \nCordialement,
                      \nAGENCY BANKING
                      `
      const smsResult = await sendSMS(formData.phone, message);
      if (!smsResult.success) {
        console.error(smsResult.message);
      }
      // Passage à l'étape suivante
      
    } catch (error) {
      console.error('Erreur lors de la soumission des données:', error);
      setLoading(false);
    }
  };

  const handlePrevious = () => setStep(2);
  const {
    bank, account_type, name, firstname, email, phone, resid, job, professional_status, company, id_card_type, id_card_number
  } = formData;
  console.log(formData)
  return (
    <>
      <div className="p-2 h-[auto] sm:basis-[60%]  w-full sm:h-[100%]  sm:pr-[80px] overflow-y-auto ">
        <h1 className="mt-10 text-3xl font-[800] mb-2 text-primary-marineBlue">
          Récapitulatif
        </h1>
        <p className="text-neutral-coolGray mb-6">
          S'il vous plaît veuillez vérifier vos informations avant de valider votre demande
        </p>

        <form className="flex flex-col">
          <div className="form-wrapper flex flex-row flex-wrap relative">
            {[
              { label: "Etablissement banquaire", value: bank },
              { label: "Type de compte", value: account_type },
              { label: "Nom", value: name },
              { label: "Prénom", value: firstname },
              { label: "E-mail", value: email },
              { label: "Téléphone", value: phone },
              { label: "Lieu de résidence", value: resid },
              { label: "Activité", value: job },
              { label: "Statut professionelle", value: professional_status },
              { label: "Entreprise", value: company },
              { label: "Type de carte d'idetité", value: id_card_type },
              { label: "Numéro de carte d'identité", value: id_card_number }
            ].map((input, index) => (
              <div key={index} className='w-[50%] block'>
                <label className="text-primary-marineBlue font-[500] mb-2 w-[98%]">{input.label}</label>
                <input
                  className="mb-6 outline outline-1 outline-neutral-lightGray rounded-[4px] p-3 w-[98%]"
                  type="text"
                  value={input.value}
                  disabled
                />
              </div>
            ))}
          </div>
        </form>

        <div className='flex justify-between'>
          <button
            className="bg-primary-marineBlue text-white border-0 rounded-md px-6 py-3 transition-all duration-300 hover:opacity-75"
            onClick={handlePrevious}
          >
            Etape précédente
          </button>
          <button
            className="bg-primary-marineBlue text-white border-0 rounded-md px-6 py-3 transition-all duration-300 hover:opacity-75"
            onClick={handleValidation}
          >
            Valider
          </button>
        </div>
      </div>
      {loading && (
        <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <ClipLoader
                loading={loading}
                size={75}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        </div>
      )

      }


    </>

  );
};

export default Step3;