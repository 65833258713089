import React from 'react';
import { Link } from 'react-router-dom';
import CarouselSection from './Carousel';

const Main = () => {
  return (
    <>
      {/* Start block */}
      <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h3 className="max-w-2xl mb-4 text-2xl font-extrabold leading-none tracking-tight md:text-4xl xl:text-4xl dark:text-white">
              Avec le service <br/>
              Agency<span className='text-cyan-500'> banking</span><br/>
              Ouvrez votre compte bancaire <br />
              Quand &amp; où vous voulez.
            </h3>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              Demandez l'ouverture de votre compte bancaire en quelques minutes, 
              depuis n'importe où, en utilisant notre plateforme sécurisée et facile d'accès.
            </p>
            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <Link
                to={"/nouvelle-demande"}
                className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center bg-teal-700 text-white border border-gray-200 rounded-lg sm:w-auto hover:bg-teal-400 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800 animate-pulse"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                </svg>
                {" "}
                Ouvrir un compte
              </Link>
              <Link
                to={"/"}
                className="inline-flex items-center justify-center w-full px-5 py-3 mb-2 mr-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:w-auto focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                {" "}
                En savoir plus
              </Link>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src="/images/visacard.png" alt="hero image" />
          </div>
        </div>
      </section>
      {/* <CarouselSection/> */}
      {/* End block */}
      
      {/* Start Partners name */}


      {/* <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16">
          <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 sm:grid-cols-3 lg:grid-cols-6 dark:text-gray-400">
            <a href="#" className="flex items-center lg:justify-center">
              <img class=" h-9 hover:text-gray-900 dark:hover:text-white" src="/svg/uba-logo.svg" alt="" />
            </a>
            <a href="#" className="flex items-center lg:justify-center">
              <img class=" h-9 hover:text-gray-900 dark:hover:text-white" src="/images/nsia-logo.png" alt="" />
            </a>
            <a href="#" className="flex items-center lg:justify-center">
              <img class=" h-9 hover:text-gray-900 dark:hover:text-white" src="/images/bhci.png" alt="" />
            </a>
            <a href="#" className="flex items-center lg:justify-center">
              <img class=" h-9 hover:text-gray-900 dark:hover:text-white" src="/images/credit-access.png" alt="" />
            </a>
            <a href="#" className="flex items-center lg:justify-center">
              <img class=" h-9 hover:text-gray-900 dark:hover:text-white" src="/images/sg.png" alt="" />
            </a>
            <a href="#" className="flex items-center lg:justify-center">
              <img class=" h-9 hover:text-gray-900 dark:hover:text-white" src="/images/bicici.png" alt="" />
            </a>
          </div>
        </div>
      </section> */}


      {/* End Partners name */}

      {/* Start block */}
      <section className="bg-gray-50 dark:bg-gray-800">
        <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
          {/* Row */}
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                Ouvrir un compte n’a jamais été aussi simple
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Gagnez du temps avec notre processus d'ouverture de compte en ligne,
                conçu pour être rapide, simple et sécurisé.
              </p>
              {/* List */}
              <ul
                role="list"
                className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  {/* Icon */}
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Processus rapide et sécurisé
                  </span>
                </li>
                <li className="flex space-x-3">
                  {/* Icon */}
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Interface utilisateur intuitive
                  </span>
                </li>
                <li className="flex space-x-3">
                  {/* Icon */}
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Service client dédié
                  </span>
                </li>
              </ul>
              <p className="mb-8 font-light lg:text-xl">
                Bénéficiez d'une assistance dédiée à chaque étape de votre demande.
              </p>
            </div>
            <img
              className="hidden mb-4 lg:mb-0 lg:flex rounded-xl"
              src="/images/banking.png"
              alt="dashboard feature image"
            />
          </div>
          {/* Row */}
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <img
              className="hidden mb-4 lg:mb-0 lg:flex rounded-xl"
              src="/images/privacy-policy.jpg"
              alt="feature image 2"
            />
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                Sécurité et confidentialité maximales
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Nous prenons la sécurité de vos informations personnelles très au sérieux. 
                Notre plateforme utilise les technologies les plus avancées pour protéger vos données.
              </p>
              {/* List */}
              <ul
                role="list"
                className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  {/* Icon */}
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Données chiffrées de bout en bout
                  </span>
                </li>
                <li className="flex space-x-3">
                  {/* Icon */}
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Protection avancée contre les cybermenaces
                  </span>
                </li>
                <li className="flex space-x-3">
                  {/* Icon */}
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Conformité aux normes internationales
                  </span>
                </li>
              </ul>
              <p className="mb-8 font-light lg:text-xl">
                Nous nous engageons à respecter les normes les plus strictes en matière de sécurité et de confidentialité.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* End block */}
      {/* Start block */}
      <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
          <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              Des milliers de clients satisfaits
            </h2>
            <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
              Rejoignez les milliers de clients qui ont ouvert leurs comptes bancaires en ligne avec succès.
            </p>
          </div>
          <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 lg:gap-12 lg:space-y-0">
            {/* Testimonial */}
            <div className="p-6 text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400">
              <svg
                className="w-12 h-12 mx-auto mb-3 text-gray-400 dark:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 27"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M5.25 5.25v1.5h13.5v-1.5a3 3 0 0 0-3-3H8.25a3 3 0 0 0-3 3zm13.5 4.5H5.25v12A3.75 3.75 0 0 0 9 25.5h6a3.75 3.75 0 0 0 3.75-3.75V9.75z"
                />
              </svg>
              <p className="mb-4 text-sm">
                "L'ouverture de mon compte a été d'une simplicité étonnante. Tout le processus s'est déroulé sans accroc et en un temps record."
              </p>
              <h3 className="font-semibold text-gray-900 dark:text-white">Jean Dupont</h3>
            </div>
            {/* Testimonial */}
            <div className="p-6 text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400">
              <svg
                className="w-12 h-12 mx-auto mb-3 text-gray-400 dark:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 27"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M5.25 5.25v1.5h13.5v-1.5a3 3 0 0 0-3-3H8.25a3 3 0 0 0-3 3zm13.5 4.5H5.25v12A3.75 3.75 0 0 0 9 25.5h6a3.75 3.75 0 0 0 3.75-3.75V9.75z"
                />
              </svg>
              <p className="mb-4 text-sm">
                "J'ai ouvert mon compte en ligne en quelques clics seulement. Je recommande vivement cette plateforme."
              </p>
              <h3 className="font-semibold text-gray-900 dark:text-white">Marie Curie</h3>
            </div>
            {/* Testimonial */}
            <div className="p-6 text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400">
              <svg
                className="w-12 h-12 mx-auto mb-3 text-gray-400 dark:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 27"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M5.25 5.25v1.5h13.5v-1.5a3 3 0 0 0-3-3H8.25a3 3 0 0 0-3 3zm13.5 4.5H5.25v12A3.75 3.75 0 0 0 9 25.5h6a3.75 3.75 0 0 0 3.75-3.75V9.75z"
                />
              </svg>
              <p className="mb-4 text-sm">
                "Le service client est exceptionnel. Ils ont répondu à toutes mes questions rapidement et efficacement."
              </p>
              <h3 className="font-semibold text-gray-900 dark:text-white">Claude Monet</h3>
            </div>
          </div>
        </div>
      </section>
      {/* End block */}
    </>
  );
};

export default Main;
