import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Step4 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 5000);
  }, [navigate]);

  return (
    <div className="sm:basis-[60%] w-[300px] sm:w-[100%] h-[100%] sm:pr-[80px] flex flex-col justify-center items-center">
      <img className="h-[80px] w-[80px]" src={"/svg/icon-thank-you.svg"} alt="checkmark" />
      <h1 className="mt-10 text-3xl font-[800] mb-2 text-primary-marineBlue">
        Demande envoyée!
      </h1>
      <p className="text-neutral-coolGray mt-3 text-center">
        Merci pour votre confiance! Nous espérons que vous avez aimé votre expérience sur notre plateforme.
        Vous serez redirigé vers l'accueil dans quelques instants.
      </p>
    </div>
  );
};

export default Step4;