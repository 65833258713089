import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import SideNav from '../components/Dashboard/SideNav';
import Header from '../components/Dashboard/Header';
import { CreditRequestByEmailUrl, updateCreditStatusRequestUrl, updateCreditAdditionalInfoRequestUrl, updateCreditAppointmentRequestUrl, updateCreditReasonForRefusalRequestUrl, downloadDocUrl, sendSmsUrl } from '../url';
import { FaCheckCircle, FaCalendarAlt, FaPlusCircle, FaTimesCircle } from "react-icons/fa";
import { IoIosCloudDownload } from "react-icons/io";
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Navigation from '../components/Dashboard/Navigation';


const DetailsCreditUsersPage = () => {

  const navigate = useNavigate();

  const { email } = useParams();
  const [user, setUser] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [currentAgent, setCurrentAgent] = useState(null)
  const [isMissingInfoDialogOpen, setMissingInfoDialogOpen] = useState(false);
  const [isAppointmentDialogOpen, setAppointmentDialogOpen] = useState(false);
  const [isValidationDialogOpen, setValidationDialogOpen] = useState(false);
  const [isSubmitNextDialogOpen, setSubmitNextDialogOpen] = useState(false);
  const [isRejectionDialogOpen, setRejectionDialogOpen] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [missingInfo, setMissingInfo] = useState({});
  const [refusedReason, setRefusedReason] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '', severity: '' });


  const admin = JSON.parse(window?.localStorage.getItem("adminAccess"));

  useEffect(() => {
    if (admin.type_agent) {
      const fetchAgentData = async () => {
        setCurrentAgent(admin.type_agent);
        console.log(admin);
      };
      fetchAgentData();
    }
  }, [admin]);

  console.log(currentAgent)

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const response = await axios.get(`${CreditRequestByEmailUrl}/${email}`);
        setUser(response.data);
        setRequestId(response.data.credit_request_id);
      } catch (error) {
        console.error('Error fetching request details:', error);
      }
    };

    fetchRequest();
  }, [email]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(`${downloadDocUrl}/${requestId}`);
        setDocuments(response.data);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, [requestId]);

  // const sendSMS = async (phoneNumber, message) => {
  //   try {
  //     const response = await axios.post(sendSmsUrl, { phoneNumber, message });
  //     if (response.data.success) {
  //       console.log('SMS envoyé avec succès');
  //     } else {
  //       console.error('Erreur lors de l\'envoi du SMS');
  //     }
  //   } catch (error) {
  //     console.error('Erreur:', error.message);
  //   }
  // };

  // console.log("doc", documents);

  const sendSMS = async (phoneNumber, message) => {

    const username = "fintechhyksos";
    const password = "GIUdoZ6dJHpkzNqHZbbsSpIwOJHHHQaK";
    const sender = "MT4africa";
    const recipient = phoneNumber;
    const msg = message;
    try {

      const data = new URLSearchParams();
      data.append('username', username);
      data.append('password', password);
      data.append('msisdn', recipient);
      data.append('msg', msg);
      data.append('sender', sender);
      data.append('allowunicode', 'true');

      setLoading(true);

      await axios.post(
        "https://api-public-2.mtarget.fr/messages", data,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );
      alert("SMS envoyé avec succès");
    } catch (error) {
      console.error("Erreur lors de l'envoi du SMS :", error);
      alert("Erreur lors de l'envoi du SMS");
    } finally {
      setLoading(false);
    }
  };

  // const formattedbirthDay = new Intl.DateTimeFormat('fr-FR', {
  //   day: '2-digit',
  //   month: '2-digit',
  //   year: 'numeric',
  // }).format(new Date(user?.birth_day));

  const handleStatusUpdate = async (newStatus) => {
    try {
      let message = "";

      const formattedDate = new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(new Date(appointmentDate));

      if (newStatus === 'refused') {
        openRejectionDialog();
        return;
      }
      if (newStatus === 'confirmed') {
        openValidationDialog();
        return;
      }
      if (newStatus === 'validated_by_account_manager' || newStatus === 'validated_by_credit_analyst' || newStatus === 'validated_by_risk_analyst') {
        openSubmitNextDialog();
        return;
      }
      if (newStatus === 'additional_info_by_account_manager') {
        openMissingInfoDialog();
        return;
      }
      if (newStatus === 'appointment_by_credit_analyst' || newStatus === 'appointment_by_account_manager') {
        openAppointmentDialog();
        return;
      }


      // if (newStatus === 'refused') {
      //   await axios.post(`${updateCreditStatusRequestUrl}/${email}`, {
      //     request_id: requestId,
      //     status: newStatus,
      //   });

      //   await axios.post(`${updateCreditReasonForRefusalRequestUrl}/${email}`, {
      //     request_id: requestId,
      //     status: newStatus,
      //   });
      // }
      // if (newStatus === 'confirmed') {
      //   await axios.post(`${updateCreditStatusRequestUrl}/${email}`, {
      //     request_id: requestId,
      //     status: newStatus,
      //   });
      // }
      // if (newStatus === 'validated_by_account_manager' || newStatus === 'validated_by_credit_analyst' || newStatus === 'validated_by_risk_analyst') {
      //   await axios.post(`${updateCreditStatusRequestUrl}/${email}`, {
      //     request_id: requestId,
      //     status: newStatus,
      //   });
      // }
      // if (newStatus === 'additional_info_by_account_manager') {
      //   await axios.post(`${updateCreditStatusRequestUrl}/${email}`, {
      //     request_id: requestId,
      //     status: newStatus,
      //   });

      //   await axios.post(`${updateCreditAdditionalInfoRequestUrl}/${email}`, {
      //     request_id: requestId,
      //     status: newStatus,
      //   });
      // }
      // if (newStatus === 'appointment_by_credit_analyst' || newStatus === 'appointment_by_account_manager') {
      //   await axios.post(`${updateCreditStatusRequestUrl}/${email}`, {
      //     request_id: requestId,
      //     status: newStatus,
      //   });

      //   await axios.post(`${updateCreditAppointmentRequestUrl}/${email}`, {
      //     request_id: requestId,
      //     status: newStatus,
      //   });
      // }


      switch (newStatus) {
        case 'validated_by_account_manager':
          message = "Votre demande a été validée par le gestionnaire de compte.";
          break;
        case 'appointment_by_account_manager':
          message = `Votre rendez-vous est fixé au ${formattedDate}.`;
          break;
        case 'additional_info_by_account_manager':
          message = "Le gestionnaire de compte en charge du dossier demande des informations supplémentaires concernant votre demande.";
          break;
        case 'sent_back_to_account_manager':
          message = "La demande a été renvoyée au gestionnaire de compte pour révision.";
          break;
        case 'validated_by_credit_analyst':
          message = "Votre demande a été validée par l'analyste credit.";
          break;
        case 'appointment_by_credit_analyst':
          message = `Votre rendez-vous est fixé au ${formattedDate}.`;
          break;
        case 'reassess_credit_by_risk_analyst':
          message = "La réévaluation du crédit a été réalisée par l'analyste risque en charge du dossier.";
          break;
        case 'validated_by_risk_analyst':
          message = "L'évaluation des risques a été réalisée par l'analyste risque";
          break;
        case 'sent_back_to_credit_analyst':
          message = "La demande a été renvoyée à l'analyste credit pour révision.";
          break;
        case 'confirmed':
          message = "Votre demande a été validée .";
          break;
        case 'refused':
          message = "Votre demande a été refusée.";
          break;
        default:
          message = "";
      }

      if (message) {
        const smsResult = await sendSMS(user.phone, message);
        if (!smsResult.success) {
          toast.error(smsResult.message);
        }
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut ou de l'envoi de l'email :", error);
    }
  };

  console.log(user);

  const openMissingInfoDialog = () => setMissingInfoDialogOpen(true);
  const closeMissingInfoDialog = () => setMissingInfoDialogOpen(false);

  const openAppointmentDialog = () => setAppointmentDialogOpen(true);
  const closeAppointmentDialog = () => setAppointmentDialogOpen(false);

  const openValidationDialog = () => setValidationDialogOpen(true);
  const closeValidationDialog = () => setValidationDialogOpen(false);

  const openSubmitNextDialog = () => setSubmitNextDialogOpen(true);
  const closeSubmitNextDialog = () => setSubmitNextDialogOpen(false);

  const openRejectionDialog = () => setRejectionDialogOpen(true);
  const closeRejectionDialog = () => setRejectionDialogOpen(false);

  const handleAppointmentDateChange = (date) => setAppointmentDate(date);

  const renderActions = () => {

    if (!currentAgent) return null;
    switch (currentAgent) {
      case 'Gestionnaire de compte':
        return (
          <>
            <div onClick={() => handleStatusUpdate('validated_by_account_manager')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-emerald-300 bg-teal-500">
              Soumettre à la prochaine étape
              <button className=' text-2xl z-100 text-emerald-500  rounded-full p-1'><FaCheckCircle /></button>
            </div>
            <div onClick={() => handleStatusUpdate('additional_info_by_account_manager')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-gray-300 bg-teal-500">
              Informations manquantes
              <span className=' text-2xl z-100 text-white p-1'><FaPlusCircle /></span>
            </div>
            <div onClick={() => handleStatusUpdate('appointment_by_account_manager')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-orange-300 bg-teal-500">
              Prise de rendez-vous
              <span className=' text-2xl z-100 text-orange-500 p-1'><FaCalendarAlt /></span>
            </div>
            <div onClick={() => handleStatusUpdate('refused')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-red-300 bg-teal-500">
              Refusé la demande
              <span className=' text-2xl z-100 text-red-500 p-1'><FaTimesCircle /></span>
            </div>
          </>
        );
      case 'Analyste credit':
        return (
          <>
            <div onClick={() => handleStatusUpdate('validated_by_credit_analyst')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-emerald-300 bg-teal-500">
              Soumettre à la prochaine étape
              <button className=' text-2xl z-100 text-emerald-500  rounded-full p-1'><FaCheckCircle /></button>
            </div>
            <div onClick={() => handleStatusUpdate('sent_back_to_account_manager')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-gray-300 bg-teal-500">
              Renvoyer au gestionnaire de compte
              <span className=' text-2xl z-100 text-white p-1'><FaPlusCircle /></span>
            </div>
            <div onClick={() => handleStatusUpdate('appointment_by_credit_analyst')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-orange-300 bg-teal-500">
              Prise de rendez-vous
              <span className=' text-2xl z-100 text-orange-500 p-1'><FaCalendarAlt /></span>
            </div>
            <div onClick={() => handleStatusUpdate('refused')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-red-300 bg-teal-500">
              Refusé la demande
              <span className=' text-2xl z-100 text-red-500 p-1'><FaTimesCircle /></span>
            </div>

          </>
        );
      case 'Analyste risque':
        return (
          <>
            <div onClick={() => handleStatusUpdate('validated_by_risk_analyst')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-emerald-300 bg-teal-500">
              Soumettre a la prochaine étape
              <button className=' text-2xl z-100 text-emerald-500  rounded-full p-1'><FaCheckCircle /></button>
            </div>
            <div onClick={() => handleStatusUpdate('sent_back_to_credit_analyst')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-gray-300 bg-teal-500">
              Renvoyer à l'analyste credit
              <span className=' text-2xl z-100 text-white p-1'><FaPlusCircle /></span>
            </div>
            <div onClick={() => handleStatusUpdate('reassess_credit_by_risk_analyst')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-orange-300 bg-teal-500">
              Évaluer les risques et estimer le montant
              <span className=' text-2xl z-100 text-orange-500 p-1'><FaCalendarAlt /></span>
            </div>
            <div onClick={() => handleStatusUpdate('refused')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-red-300 bg-teal-500">
              Refusé la demande
              <span className=' text-2xl z-100 text-red-500 p-1'><FaTimesCircle /></span>
            </div>

          </>
        );
      case 'Validateur':
        return (
          <>
            <div onClick={() => handleStatusUpdate('confirmed')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-emerald-300 bg-teal-500">
              Allouer le crédit
              <button className=' text-2xl z-100 text-emerald-500  rounded-full p-1'><FaCheckCircle /></button>
            </div>
            <div onClick={() => handleStatusUpdate('')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-gray-300 bg-teal-500">
              Réévaluer le montant estimé
              <span className=' text-2xl z-100 text-white p-1'><FaPlusCircle /></span>
            </div>
            <div onClick={() => handleStatusUpdate('refused')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-red-300 bg-teal-500">
              Refusé la demande
              <span className=' text-2xl z-100 text-red-500 p-1'><FaTimesCircle /></span>
            </div>

          </>
        );
      default:
        return null;
    }
  };



  const submitMissingInfo = async () => {
    try {
      setLoading(true);
      await axios.post(`${updateCreditAdditionalInfoRequestUrl}/${user.email}`, {
        status: "missing_info",
        email: user.email,
        request_id: requestId,
        additional_info: missingInfo.additionalInfo,
      });
      const message = "tu a des documents manquants"
      const smsResult = await sendSMS(user.phone, message);
      if (!smsResult.success) {
        toast.error(smsResult.message);
      }
      setLoading(false);
      closeValidationDialog();
      setAlert({ open: true, message: 'Demande de completion de documents envoyé ', severity: 'success' });
      setTimeout(() => {
        navigate('/dashboard/credit/nouvelles-demandes');
      }, 3000); // 3000 ms = 3 secondes
    } catch (error) {
      setLoading(false);
      setAlert({ open: true, message: 'Erreur lors de la demande de complétion de document', severity: 'error' });
      console.error('Erreur lors de la demande de complétion de document :', error);
    }
  };

  const submitAppointmentDate = async () => {
    if (currentAgent === "Gestionnaire de compte") {
      try {
        setLoading(true);
        await axios.post(`${updateCreditAppointmentRequestUrl}/${user.email}`, {
          request_id: requestId,
          status: 'appointment_by_account_manager',
          appointment_date: appointmentDate,
        });
        setLoading(false);
        closeValidationDialog();
        setAlert({ open: true, message: 'Demande de la prise rendez-vous soumise ', severity: 'success' });
        setTimeout(() => {
          navigate('/dashboard/credit/nouvelles-demandes');
        }, 3000); // 3000 ms = 3 secondes
      } catch (error) {
        setLoading(false);
        setAlert({ open: true, message: 'Erreur lors de la soumission de de rendez-vous', severity: 'error' });
        console.error('Erreur lors de la soumission de prise de rendez-vous :', error);
      }
    }
    if (currentAgent === "Analyste credit") {
      try {
        setLoading(true);
        await axios.post(`${updateCreditAppointmentRequestUrl}/${user.email}`, {
          request_id: requestId,
          status: 'appointment_by_credit_analyst',
          appointment_date: appointmentDate,
        });
        setLoading(false);
        closeValidationDialog();
        setAlert({ open: true, message: 'Demande de la prise rendez-vous soumise ', severity: 'success' });
        setTimeout(() => {
          navigate('/dashboard/credit/nouvelles-demandes');
        }, 3000); // 3000 ms = 3 secondes
      } catch (error) {
        setLoading(false);
        setAlert({ open: true, message: 'Erreur lors de la soumission de de rendez-vous', severity: 'error' });
        console.error('Erreur lors de la soumission de prise de rendez-vous :', error);
      }
    }
  };

  const submitNextStep = async () => {
    if (currentAgent === "Gestionnaire de compte") {
      setLoading(true);
      try {
        await axios.post(`${updateCreditStatusRequestUrl}/${user.email}`, {
          request_id: requestId,
          status: 'validated_by_account_manager',
        });
        setLoading(false);
        closeValidationDialog();
        setAlert({ open: true, message: 'Demande Soumise à la prochaine étape.', severity: 'success' });
        setTimeout(() => {
          navigate('/dashboard/credit/nouvelles-demandes');
        }, 3000); // 3000 ms = 3 secondes
      } catch (error) {
        setLoading(false);
        setAlert({ open: true, message: 'Erreur lors de la soumission de la demande', severity: 'error' });
        console.error('Erreur lors de la soumission de la demande :', error);
      }
    }
    console.log(requestId)
    if (currentAgent === "Analyste credit") {
      setLoading(true);
      try {
        await axios.post(`${updateCreditStatusRequestUrl}/${user.email}`, {
          request_id: requestId,
          status: 'validated_by_credit_analyst',
        });
        setLoading(false);
        closeValidationDialog();
        setAlert({ open: true, message: 'Demande Soumise à la prochaine étape.', severity: 'success' });
        setTimeout(() => {
          navigate('/dashboard/credit/nouvelles-demandes');
        }, 3000); // 3000 ms = 3 secondes
      } catch (error) {
        setLoading(false);
        setAlert({ open: true, message: 'Erreur lors de la soumission de la demande', severity: 'error' });
        console.error('Erreur lors de la soumission de la demande :', error);
      }
    }

    if (currentAgent === "Analyste risque") {
      setLoading(true);
      try {
        await axios.post(`${updateCreditStatusRequestUrl}/${user.email}`, {
          request_id: requestId,
          status: 'validated_by_risk_analyst',
        });
        setLoading(false);
        closeValidationDialog();
        setAlert({ open: true, message: 'Demande Soumise à la prochaine étape.', severity: 'success' });
        setTimeout(() => {
          navigate('/dashboard/credit/nouvelles-demandes');
        }, 3000); // 3000 ms = 3 secondes
      } catch (error) {
        setLoading(false);
        setAlert({ open: true, message: 'Erreur lors de la soumission de la demande', severity: 'error' });
        console.error('Erreur lors de la soumission de la demande :', error);
      }
    }

  };

  const submitValidation = async () => {
    try {
      await axios.post(`${updateCreditStatusRequestUrl}/${user.email}`, {
        request_id: requestId,
        status: 'Confirmed',
      });
      setLoading(false);
        closeValidationDialog();
        setAlert({ open: true, message: 'Demande validé', severity: 'success' });
        setTimeout(() => {
          navigate('/dashboard/credit/nouvelles-demandes');
        }, 3000); // 3000 ms = 3 secondes
      } catch (error) {
        setLoading(false);
        setAlert({ open: true, message: 'Erreur lors de la validation de la demande', severity: 'error' });
        console.error('Erreur lors de la validation de la demande :', error);
      }
  };

  const submitRejection = async () => {
    try {
      setLoading(true);
      await axios.post(`${updateCreditStatusRequestUrl}/${user.email}`, {
        request_id: requestId,
        status: 'refused',
        reason_for_refusal: refusedReason.reasonForRefusal
      });
      setLoading(false);
        closeValidationDialog();
        setAlert({ open: true, message: 'Demande rejetté', severity: 'success' });
        setTimeout(() => {
          navigate('/dashboard/credit/nouvelles-demandes');
        }, 3000); // 3000 ms = 3 secondes
      } catch (error) {
        setLoading(false);
        setAlert({ open: true, message: 'Erreur lors du refus de la demande', severity: 'error' });
        console.error('Erreur lors du refus de la demande :', error);
      }
  };

  if (!user) {
    return <div>Chargement...</div>;
  }
  const birth_day = new Intl.DateTimeFormat('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(user?.birth_day));
  return (
    <>


      <main className="w-full h-screen flex flex-row relative">
        <Navigation />
        <section className="flex flex-col p-10 ml-20 mb-20 w-full gap-5">
          <h1 className="text-4xl text-teal-800">Tableau de bord</h1>

          {/* Overview Section */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6 p-5">
            <div className="bg-white border border-gray-100 shadow-md shadow-black/5 p-6 rounded-md lg:col-span-2">
              <div className="flex justify-between mb-4 items-start">
                <div className="font-medium">Informations de la demande</div>

              </div>
              <div className="p-3 flex justify-between flex-wrap">
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden ">

                  <span className="text-gray-400 text-sm">Nom</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.name}</div>
                    {/* <span className="p-1 rounded text-[12px] font-semibold bg-blue-500/10 text-blue-500 leading-none ml-1">
                        $80
                      </span> */}
                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Prénom</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.firstname}</div>

                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Date de naissance</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">
                      {birth_day}
                    </div>

                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Téléphone</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.phone}</div>
                    {/* <span className="p-1 rounded text-[12px] font-semibold bg-blue-500/10 text-blue-500 leading-none ml-1">
                        $80
                      </span> */}
                  </div>
                </div>
              </div>
              <div className="p-3 flex justify-between flex-wrap">

                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">E-mail</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.email}</div>

                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Adresse</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.address}</div>

                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Type de pièce d'identité</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.id_card_type}</div>
                    {/* <span className="p-1 rounded text-[12px] font-semibold bg-blue-500/10 text-blue-500 leading-none ml-1">
                        $80
                      </span> */}
                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Numéro de la pièce d'identité</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.id_card_number}</div>

                  </div>
                </div>
              </div>
              <div className="p-3 flex justify-between flex-wrap">

                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Statut professionelle</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.professional_status}</div>

                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Métier</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.job}</div>
                    {/* <span className="p-1 rounded text-[12px] font-semibold bg-blue-500/10 text-blue-500 leading-none ml-1">
                        $80
                      </span> */}
                  </div>
                </div>
                {(user.professional_status === "Employé" || user.professional_status === "Employeur") && (
                  <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                    <span className="text-gray-400 text-sm">Entreprise</span>
                    <div className="flex items-center mb-0.5">
                      <div className="text-xl font-semibold">{user.company}</div>

                    </div>
                  </div>
                )
                }

                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Type de compte</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.account_type}</div>

                  </div>
                </div>
              </div>
              <div className="p-3 flex justify-between flex-wrap">
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden ">

                  <span className="text-gray-400 text-sm">Numéro de compte</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.account_number}</div>
                    {/* <span className="p-1 rounded text-[12px] font-semibold bg-blue-500/10 text-blue-500 leading-none ml-1">
                        $80
                      </span> */}
                  </div>
                </div>
                <div className="rounded-md border border-dashed border-gray-200 p-4 lg:w-[48%] md:w-[100%] m-1 overflow-hidden">

                  <span className="text-gray-400 text-sm">Prénom</span>
                  <div className="flex items-center mb-0.5">
                    <div className="text-xl font-semibold">{user.amount}</div>

                  </div>
                </div>


              </div>
              <div className="p-3 flex justify-between flex-wrap">

              </div>

            </div>
            <div className="bg-white border border-gray-100 shadow-md shadow-black/5 p-6 rounded-md">
              <div className="flex justify-between mb-4 items-start">
                <div className="font-medium">Documents</div>
              </div>
              <div className="overflow-x-auto">
                <div className=" gap-4">
                  <div className="py-3 bg-white rounded-xl space-y-4">
                    <div className="p-5 rounded-md border border-dashed border-gray-200">

                      <span className="text-gray-400 text-sm">Pièces jointes</span>
                      <div className="flex items-center mb-0.5">
                        <div className="text-xl font-semibold">
                          {documents.map(doc => (
                            <div key={doc.id}>
                              <a href={doc.file_url} className='flex' target="_blank" rel="noopener noreferrer">
                                <span><IoIosCloudDownload /></span><span>document</span>
                              </a>
                            </div>
                          ))}
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

                <div className="flex justify-between mb-4 items-start">
                  <div className="font-medium">Décisions</div>
                </div>
                <div className="gap-4">
                  {/* <div onClick={() => handleStatusUpdate('validated')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-emerald-300 bg-teal-500">
                    Envoyé à la prochaine étape
                    <button className=' text-2xl z-100 text-emerald-500  rounded-full p-1'><FaCheckCircle /></button>
                  </div>
                  <div onClick={() => handleStatusUpdate('pending')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-gray-300 bg-teal-500">
                    Informations manquantes
                    <span className=' text-2xl z-100 text-white p-1'><FaPlusCircle /></span>
                  </div>
                  <div onClick={() => handleStatusUpdate('appointment')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-orange-300 bg-teal-500">
                    Prise de rendez-vous
                    <span className=' text-2xl z-100 text-orange-500 p-1'><FaCalendarAlt /></span>
                  </div>
                  <div onClick={() => handleStatusUpdate('refused')} className="text-white p-5 rounded-md border  border-solid border-gray-200 text-lg flex justify-between cursor-pointer my-2 transition duration-350 hover:bg-red-300 bg-teal-500">
                    Refusé la demande
                    <span className=' text-2xl z-100 text-red-500 p-1'><FaTimesCircle /></span>
                  </div> */}
                  {renderActions()}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>



      {isMissingInfoDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Ajouter des informations manquantes</h2>
            <div>
              <label className="block mb-2">Informations:</label>
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md"
                rows="4"
                value={missingInfo.additionalInfo || ''}
                onChange={(e) => setMissingInfo({ additionalInfo: e.target.value })}
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
                onClick={closeMissingInfoDialog}
              >
                Annuler
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
                onClick={submitMissingInfo}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Soumettre'}
              </button>
            </div>
          </div>
        </div>
      )}

      {isAppointmentDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Prendre rendez-vous</h2>
            <div>
              <label className="block mb-2">Date du rendez-vous:</label>
              <input
                type="date"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={appointmentDate || ''}
                onChange={(e) => handleAppointmentDateChange(e.target.value)}
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
                onClick={closeAppointmentDialog}
              >
                Annuler
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
                onClick={submitAppointmentDate}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Soumettre'}
              </button>
            </div>
          </div>
        </div>
      )}

      {isValidationDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Valider la demande</h2>
            <p>Êtes-vous sûr de vouloir valider cette demande ?</p>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
                onClick={closeValidationDialog}
              >
                Annuler
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded-md"
                onClick={submitValidation}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Valider'}
              </button>
            </div>
          </div>
        </div>
      )}
      {isSubmitNextDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Soumettre à la prochaine étape</h2>
            <p>Êtes-vous sûr de vouloir soumettre cette demande ?</p>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
                onClick={closeSubmitNextDialog}
              >
                Annuler
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded-md"
                onClick={submitNextStep}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Soumettre'}
              </button>
            </div>
          </div>
        </div>
      )}
      {isRejectionDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Refuser la demande</h2>
            <p>Êtes-vous sûr de vouloir refuser cette demande ?</p>
            <div>
              <label className="block mb-2">Indiquer les Raisons:</label>
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md"
                rows="4"
                value={refusedReason.reasonForRefusal || ''}
                onChange={(e) => setRefusedReason({ reasonForRefusal: e.target.value })}
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
                onClick={closeRejectionDialog}
              >
                Annuler
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md"
                onClick={submitRejection}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Valider'}
              </button>
            </div>
          </div>
        </div>
      )}
      {loading && <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">Chargement...</div>}
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={() => setAlert({ ...alert, open: false })}>
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DetailsCreditUsersPage;