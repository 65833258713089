import React from 'react';

const DetailsCreditManagement = () => {
  return (
    <div>
      
    </div>
  );
};

export default DetailsCreditManagement;