import { BriefcaseIcon, ClockIcon, CheckIcon, XCircleIcon } from "@heroicons/react/24/outline";
import Navigation from "../Dashboard/Navigation";
import DataTableAccount from "../table/DataTableAccount";
import DataTableCredit from "../table/DataTableCredit";
import { bankStatisticsUrl } from "../../url";
import { CreditStatisticsUrl } from "../../url";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

const LayoutDashboard = () => {
  const admin = JSON.parse(window?.localStorage.getItem("adminAccess"));
  console.log(admin);

  const [creditStatistics, setCreditStatistics] = useState({
    total: 0,
    confirmed: 0,
    refused: 0,
    ongoing: 0
  });
  const [accountStatistics, setAccountStatistics] = useState({
    total: 0,
    confirmed: 0,
    refused: 0,
    ongoing: 0
  });

  useEffect(() => {
    const fetchCreditStats = async () => {
      const response = await axios.get(`${CreditStatisticsUrl}/${admin.institution_name}`);
      if (response.ok) {
        const data = await response.json();
        setCreditStatistics(data);
      } else {
        console.error('Failed to fetch stats');
      }
    };

    fetchCreditStats();
  }, []);

  useEffect(() => {
    const fetchBankStats = async () => {
      const response = await axios.get(`${bankStatisticsUrl}/${admin.institution_name}`);

      if (response.ok) {
        const data = await response.json();
        setAccountStatistics(data);
      } else {
        console.error('Failed to fetch stats');
      }
    };

    fetchBankStats();
  }, []);



  return (
    <main className="w-full h-screen flex flex-row relative">
      <Navigation />
      <section className="flex flex-col p-10 ml-20 mb-20 w-full gap-5">
        <h1 className="text-4xl text-teal-800">Dashboard</h1>

        <h1 className="text-2xl font-bold text-gray-800 mb-4">Demandes d'ouverture de compte</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <div className="flex items-center p-5 bg-white rounded-lg shadow">
            <BriefcaseIcon className="w-8 h-8 text-teal-600 mr-4" />
            <div>
              <p className="text-lg font-semibold text-gray-700">Demandes totales</p>
              <p className="text-3xl font-bold text-gray-900">{accountStatistics.total}</p>
            </div>
          </div>
          <div className="flex items-center p-5 bg-white rounded-lg shadow">
            <ClockIcon className="w-8 h-8 text-teal-600 mr-4" />
            <div>
              <p className="text-lg font-semibold text-gray-700">Demandes en cours</p>
              <p className="text-3xl font-bold text-gray-900">{accountStatistics.ongoing}</p>
            </div>
          </div>
          <div className="flex items-center p-5 bg-white rounded-lg shadow">
            <CheckIcon className="w-8 h-8 text-teal-600 mr-4" />
            <div>
              <p className="text-lg font-semibold text-gray-700">Demandes approuvées</p>
              <p className="text-3xl font-bold text-gray-900">{accountStatistics.confirmed}</p>
            </div>
          </div>
          <div className="flex items-center p-5 bg-white rounded-lg shadow">
            <XCircleIcon className="w-8 h-8 text-teal-600 mr-4" />
            <div>
              <p className="text-lg font-semibold text-gray-700">Demandes réfusées</p>
              <p className="text-3xl font-bold text-gray-900">{accountStatistics.refused}</p>
            </div>
          </div>
        </div>

        {/* Recent Activities */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Activités récentes</h2>
          <div className="overflow-auto">
            <DataTableAccount />
          </div>
        </div>
        <h1 className="text-2xl font-bold text-gray-800 mb-4">Demandes de credits</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">

          <div className="flex items-center p-5 bg-white rounded-lg shadow">
            <BriefcaseIcon className="w-8 h-8 text-teal-600 mr-4" />
            <div>
              <p className="text-lg font-semibold text-gray-700">Demandes totales</p>
              <p className="text-3xl font-bold text-gray-900">{creditStatistics.total}</p>
            </div>
          </div>
          <div className="flex items-center p-5 bg-white rounded-lg shadow">
            <ClockIcon className="w-8 h-8 text-teal-600 mr-4" />
            <div>
              <p className="text-lg font-semibold text-gray-700">Demandes en cours</p>
              <p className="text-3xl font-bold text-gray-900">{creditStatistics.ongoing}</p>
            </div>
          </div>
          <div className="flex items-center p-5 bg-white rounded-lg shadow">
            <CheckIcon className="w-8 h-8 text-teal-600 mr-4" />
            <div>
              <p className="text-lg font-semibold text-gray-700">Demandes approuvées</p>
              <p className="text-3xl font-bold text-gray-900">{creditStatistics.confirmed}</p>
            </div>
          </div>
          <div className="flex items-center p-5 bg-white rounded-lg shadow">
            <XCircleIcon className="w-8 h-8 text-teal-600 mr-4" />
            <div>
              <p className="text-lg font-semibold text-gray-700">Demandes réfusées</p>
              <p className="text-3xl font-bold text-gray-900">{creditStatistics.refused}</p>
            </div>
          </div>
        </div>

        {/* Recent Activities */}
        <div className="bg-white p-6 rounded-lg shadow ">
          <h2 className="text-xl font-bold text-gray-800 mb-4">Activités récentes</h2>
          <div className="overflow-auto">
            <DataTableCredit />
          </div>
        </div>
      </section>
    </main>
  );
};

export default LayoutDashboard;
