import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Avatar,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useLocation, NavLink } from 'react-router-dom';
import {
  MaterialReactTable,
} from 'material-react-table';
import { HiOutlinePencilAlt, HiOutlineDownload } from 'react-icons/hi';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { listCreditParNameUrl } from '../../url';

const DataTableCredit = () => {
  const location = useLocation();
  const admin = JSON.parse(localStorage.getItem('adminAccess')) || {};
  const currentAgent = admin.type_agent || '';
  const institutionName = admin.institution_name || '';
  const [pathDetails,setPathDetails] = useState('');

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${listCreditParNameUrl}/${institutionName}`);
        setData(response.data);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Erreur lors de la récupération des données.');
      } finally {
        setLoading(false);
      }
    };

    if (institutionName) {
      fetchData();
    } else {
      setLoading(false);
      setError('Nom de l\'institution non disponible.');
    }
  }, [institutionName]);

  

  // Define columns for the table

  const columns = useMemo(() => {
  
    return [
      {
        accessorKey: 'user_id',
        header: 'ID',
        size: 20,
      },
      {
        accessorKey: 'name',
        header: 'Nom',
        size: 120,
      },
      {
        accessorKey: 'phone',
        header: 'Téléphone',
        size: 120,
      },
      {
        accessorKey: 'account_type',
        header: 'Compte',
        size: 120,
      },
      {
        accessorKey: 'amount',
        header: 'Montant',
        size: 100,
      },
      {
        accessorKey: 'status',
        header: 'Statut',
        size: 220,
        Cell: ({ cell }) => renderStatus(cell.getValue()),
      },
      {
        accessorKey: 'request_date',
        header: 'Date et Heure',
        size: 180,
        Cell: ({ cell }) => formatDate(cell.getValue()),
      },
      {
        header: 'Actions',
        size: 100,
        Cell: ({ row }) => {
          // Utilisation du pathname pour dynamiser le lien
          const basePath = location.pathname.startsWith('/dashboard/credit/nouvelles-demandes')
            ? '/dashboard/credit/detail-demande'
            :location.pathname.startsWith('/dashboard/credit/check-demandes')
            ? '/dashboard/credit/detail-check-demande'
            :location.pathname.startsWith('/dashboard/accueil')
            ? '/dashboard/credit/detail-demande':'';
  
          return (
            <NavLink to={`${basePath}/${row.original.email}`}>
              <Avatar sx={{ width: 30, height: 30, bgcolor: 'primary.main' }}>
                <HiOutlinePencilAlt color="#fff" />
              </Avatar>
            </NavLink>
          );
        },
      },
    ];
  }, [location.pathname]);

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  // Function to render status with styling
  const renderStatus = (status) => {
    let color = '';
    let label = '';

    switch (status) {
      case 'new':
        color = 'info.main';
        label = 'Nouveau';
        break;
      case 'pending':
        color = 'warning.main';
        label = 'En attente';
        break;
      case 'appointment':
        color = 'secondary.main';
        label = 'Rendez-vous';
        break;
      case 'refused':
        color = 'error.main';
        label = 'Refusé';
        break;
      case 'validated':
        color = 'success.main';
        label = 'Validé';
        break;
      case 'validated_by_account_manager':
        color = 'info.dark';
        label = 'Validé par le Gestionnaire';
        break;
      case 'validated_by_credit_analyst':
        color = 'info.dark';
        label = 'Validé par l\'Analyste Crédit';
        break;
      case 'validated_by_risk_analyst':
        color = 'info.dark';
        label = 'Validé par l\'Analyste Risque';
        break;
      default:
        color = 'text.secondary';
        label = 'Inconnu';
    }

    return (
      <Box
        sx={{
          backgroundColor: color,
          color: '#fff',
          px: 2,
          py: 0.5,
          borderRadius: '4px',
          textAlign: 'center',
        }}
      >
        {label}
      </Box>
    );
  };

  // Filter data based on URL path and agent role
  const filteredData = useMemo(() => {
    if (!data || data.length === 0) return [];

    let filtered = [];

    // Filter based on URL path
    switch (location.pathname) {
      case '/dashboard/credit/nouvelles-demandes':
      case '/dashboard/accueil':
      
        filtered = data.filter(
        item => item.status === 'new' ||
        item.status === 'validated_by_account_manager' ||
        item.status === 'validated_by_risk_analyst' ||
        item.status === 'validated_by_credit_analyst'
        );
        break;
      case '/dashboard/credit/historique-demandes':
        filtered = data.filter(item => item.status !== 'new');
        break;
      case '/dashboard/credit/demandes-en-attente-de-documents':
        filtered = data.filter(item => item.status === 'missing_info');
        break;
      case '/dashboard/credit/demandes-en-attente-rendez-vous':
        filtered = data.filter(item => item.status === 'appointment');
        break;
      case '/dashboard/credit/demandes-rejetter':
        filtered = data.filter(item => item.status === 'refused');
        break;
      case '/dashboard/credit/demandes-accepter':
        filtered = data.filter(item => item.status === 'confirmed');
        break;
      default:
        filtered = data;
    }

    return filtered;
  }, [data, location.pathname]);

  console.log("filterdData", filteredData)
  const filteredAgents = useMemo(() => {
    if (!filteredData || filteredData.length === 0) return [];

    let filtered = [];

    // Further filter based on agent role
    switch (currentAgent) {
      case 'Gestionnaire de compte':
        filtered = filteredData.filter(item => item.status === 'new');
        break;
      case 'Analyste credit':
        filtered = filteredData.filter(item => item.status === 'validated_by_account_manager');
        break;
      case 'Analyste risque':
        filtered = filteredData.filter(item => item.status === 'validated_by_credit_analyst');
        break;
      case 'Validateur':
        filtered = filteredData.filter(item => item.status === 'validated_by_risk_analyst');
        break;
      case '':
        filtered = filteredData;
        break;
      default:
        filtered = [];
    }

    if(location.pathname === '/dashboard/credit/check-demandes'){
      filtered = filteredData.filter(
        item => item.status === 'new' ||
        item.status === 'validated_by_account_manager' ||
        item.status === 'validated_by_risk_analyst' ||
        item.status === 'validated_by_credit_analyst'
      );
    }

    return filtered;
  }, [ location.pathname,currentAgent,filteredData]);

  console.log( "filteredAgents",filteredAgents);

  // Function to export data to PDF
  const handleExportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns
      .filter(col => col.accessorKey && col.header !== 'Actions')
      .map(col => col.header);
    const tableRows = filteredAgents.map(item => [
      item.user_id,
      item.name,
      item.phone,
      item.account_type,
      item.amount,
      item.status,
      formatDate(item.request_date),
    ]);

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
    });

    doc.save('demandes_credit.pdf');
  };

  // Render component
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{  }}>
      {filteredAgents && filteredAgents.length > 0 ? (
        <MaterialReactTable
          columns={columns}
          data={filteredAgents}
          enableRowSelection
          enablePagination
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={() => (
            <Button
              variant="contained"
              color="primary"
              startIcon={<HiOutlineDownload />}
              onClick={handleExportToPDF}
            >
              Exporter en PDF
            </Button>
          )}
          muiTableProps={{
            sx: {
              tableLayout: 'fixed',
            },
          }}
        />
      ) : (
        <div>Aucune procédure enregistrée ici pour l'instant</div>
      )}
    </Box>
  );
};

export default DataTableCredit;
