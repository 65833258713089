import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Login from './auth/Login';
import './style/index.css';
import RequestBankPage from './pages/RequestBankPage';
import ApplyCreditPage from './pages/ApplyCreditPage';
import Layout from './components/Dashboard/Layout';
import DetailsUsersPage from './pages/DetailsUsersPage';
import { useEffect } from 'react';
import LayoutDashboard from './components/Layout/LayoutDashboard';
import SelectRequestPage from './pages/SelectRequestPage';
import DetailsCreditUsersPage from './pages/DetailsCreditUsersPage';
import LayoutManagementCreditRequests from './components/Layout/LayoutManagementCreditRequests';
import LayoutManagementAccountRequests from './components/Layout/LayoutManagementAccountRequests';
import ProfilePage from './pages/ProfilePage';
import LayoutProfile from './components/Layout/LayoutProfile';
import DetailsCreditManagement from './pages/DetailsCreditManagement';

function ProtectedRoute({ children }) {
  const user = window.localStorage.getItem("userAccess") || window.localStorage.getItem("adminAccess");

  if (!user) {
    // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
    return <Navigate to="/login" replace />;
  }

  return children;
}

function App() {
  const params = useLocation();
  const user = window.localStorage.getItem("userAccess") || window.localStorage.getItem("adminAccess");
  const navigate = useNavigate();

  useEffect(() => {
    // Simplification de la logique de redirection pour éviter des erreurs
    if (!user && params.pathname.includes("dashboard")) {
      navigate("/login");
    }
  }, [user, params.pathname, navigate]);

  return (
    <>
      <Routes>

        <Route path='/login' element={<Login />} />
        <Route path="/login/:tokenParams" element={<Login />} />
        <Route path='/nouvelle-demande' element={<SelectRequestPage />} />
        <Route path='/nouvelle-demande/bank-request' element={<RequestBankPage />} />
        <Route path='/nouvelle-demande/apply-credit' element={<ApplyCreditPage />} />
        
        <Route path="/dashboard" element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        } />
        <Route path="/dashboard/credit/*" element={
          <ProtectedRoute>
            <LayoutManagementCreditRequests />
          </ProtectedRoute>
        } />
        <Route path="/dashboard/compte/*" element={
          <ProtectedRoute>
            <LayoutManagementAccountRequests />
          </ProtectedRoute>
        } />
        <Route path='/dashboard/accueil' element={
          <ProtectedRoute>
            <LayoutDashboard />
          </ProtectedRoute>
        } />
        <Route path='/dashboard/compte/detail-demande/:email' element={
          <ProtectedRoute>
            <DetailsUsersPage />
          </ProtectedRoute>
        } />
        <Route path='/dashboard/credit/detail-demande/:email' element={
          <ProtectedRoute>
            <DetailsCreditUsersPage />
          </ProtectedRoute>
        } />
        <Route path='/dashboard/credit/detail-check-demande/:email' element={
          <ProtectedRoute>
            <DetailsCreditManagement />
          </ProtectedRoute>
        } />
        <Route path='/dashboard/mon-profile' element={
          <ProtectedRoute>
            <LayoutProfile/>
          </ProtectedRoute>
        } />
        <Route path='/select-requests' element={<SelectRequestPage />} />
        <Route path='/' element={<HomePage />} />
        
      </Routes>
    </>
  );
}

export default App;
